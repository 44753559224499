@mixin break-point($point) {
  @if $point==xllgdesktop {
    @media screen and (max-width: 1920px) {
      @content;
    }
  }
  @if $point==smlgdesktop {
    @media screen and (max-width: 1680px) {
      @content;
    }
  }
  @if $point==lgmediumdesktop {
    @media screen and (max-width: 1566px) {
      @content;
    }
  }
  @if $point==mediumdesktop {
    @media screen and (max-width: 1440px) {
      @content;
    }
  }
  @if $point==desktop {
    @media screen and (max-width: 1367px) {
      @content;
    }
  }
  @if $point==cstmdesktop {
    @media screen and (max-width: 1366px) {
      @content;
    }
  }

  @if $point ==largeTab {
    @media screen and (max-width: 1280px) {
      @content;
    }
  }

  @if $point ==landscapeTab {
    @media screen and (max-width: 1199px) {
      @content;
    }
  }
  @if $point ==lscapeTablet {
    @media screen and (max-width: 1080px) {
      @content;
    }
  }

  @if $point ==lgtablet {
    @media screen and (max-width: 992px) {
      @content;
    }
  }
  @if $point ==tablet {
    @media screen and (max-width: 991px) {
      @content;
    }
  }

  @if $point ==smalltablet {
    @media screen and (max-width: 868px) {
      @content;
    }
  }

  @if $point ==miniTab {
    @media screen and (max-width: 768px) {
      @content;
    }
  }
  @if $point ==miniTabs {
    @media screen and (max-width: 767px) {
      @content;
    }
  }
  @if $point ==mobilestart {
    @media screen and (max-width: 676px) {
      @content;
    }
  }

  @if $point ==mobile {
    @media screen and (max-width: 575px) {
      @content;
    }
  }
  @if $point ==cstmmobile {
    @media screen and (max-width: 432px) {
      @content;
    }
  }
  @if $point ==minimobile {
    @media screen and (max-width: 375px) {
      @content;
    }
  }
  @if $point ==smallmobile {
    @media screen and (max-width: 334px) {
      @content;
    }
  }
}

@mixin transition {
  transition: all 0.3s ease-in-out;
}

@mixin scroll($scroll) {
  @if $scroll == y {
    &::-webkit-scrollbar {
      height: 5px;
      width: 5px;
    }
    &::-webkit-scrollbar-track {
      background-color: #f1f1f1;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #aaa;
      // outline: 1px solid slategrey;
    }
  }
  @if $scroll == x {
    &::-webkit-scrollbar {
      height: 5px;
    }
    &::-webkit-scrollbar-track {
      background-color: #f1f1f1;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #aaa;
      // outline: 1px solid slategrey;
      border-radius: 10px;
    }
  }
}
