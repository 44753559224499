@import "../../scss/index.scss";

.wrap_booking_request {
  background-color: $white-color;
  .wrap_heading {
    padding: 15px;
    border-bottom: 1px solid $nav_border-color;
    h5 {
      font-weight: 600;
      margin-bottom: 0;
    }
  }
  .wrap_booking_heading {
    padding: 15px;
    .row {
      .wrap_listing_card {
        background-color: rgba(39, 65, 94, 0.04);
        padding: 15px;
        max-width: 470px;
        width: 100%;
        color: unset;
        .recommend_list {
          max-width: 440px;
          border: unset;
          figure {
            img {
              height: 100%;
            }
          }
          &:hover {
            box-shadow: unset;
          }
          .cstm_wrap_card_list {
            padding: 5px 0px;
            border-bottom: 1px solid $nav_border-color;
            .wrap_value {
              justify-content: unset;
              .wrap_rating {
                margin-left: 40px;
              }
              h5 {
                font-weight: 600;
              }
            }
            p {
              b {
                font-size: 18px;
              }
            }
          }
          .wrap_detail_amount {
            padding-top: 10px;
            h5 {
              font-weight: 600;
            }
            ul {
              border-bottom: 1px solid $nav_border-color;
              li {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding-bottom: 8px;
                span {
                  font-size: 14px;
                }
              }
            }
            .total_amount {
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding-top: 10px;
              span {
                font-size: 14px;
              }
            }
          }
        }
        &.cstm-list_mobile {
          @include break-point(mobile) {
            max-width: 100%;
            margin-bottom: 20px;
            .recommend_list {
              max-width: 100%;
              .cstm_wrap_card_list {
                .wrap_value {
                  justify-content: space-between;
                }
              }
            }
          }
        }
      }
      .wrap_request_book {
        .booking_details_date {
          h5 {
            font-weight: 600;
          }
          .details_ul {
            padding-right: 30px;
            border-bottom: 1px solid $nav_border-color;
            li {
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding-bottom: 20px;

              &:first-child {
                padding-top: 10px;
              }

              .wrap_date_details {
                p {
                  font-weight: 600;
                  margin-bottom: 0px;
                }
                span {
                  color: $crousal_para;
                  font-size: 14px;
                }
              }
            }
          }
          .wrap_overview {
            padding: 20px 0px;
            border-bottom: 1px solid $nav_border-color;

            h5 {
              font-weight: 600;
            }
            .wrap_things {
              padding-top: 10px;
              p {
                font-weight: 600;
                margin-bottom: 0;
              }
              span {
                color: $crousal_para;
                font-size: 14px;
              }
              .btn_see_more {
                max-width: 100px;
                min-height: 35px;
                margin-top: 15px;
              }
              ul {
                list-style: disc;
                padding-left: 18px;
                li {
                  color: $crousal_para;
                  font-size: 14px;
                }
              }
            }
            
          }
          .pay_details {
            padding: 20px 0px;
            h5 {
              font-weight: 600;
              margin-bottom: 15px;
            }
            form {
              .form_area_input {
                padding-bottom: 15px;
                label {
                  font-size: 14px;
                  padding-bottom: 5px;
                  color: $crousal_para;
                }
                &.form_input_flex {
                  display: flex;
                  justify-content: space-between;
                  .wrap_label_inpu {
                    max-width: 45%;
                    width: 100%;
                  }
                }
              }
              .btn_book_now {
                justify-content: right;
                display: flex;
                .btn_see_more {
                  background-color: $btn_border-common;
                  border: 1px solid $btn_border-common;
                  color: #ffffff;
                  max-width: 200px;
                  width: 100%;
                  font-weight: 600;
                }
              }
            }
          }
        }
      }
    }
  }
}

.wrap_booking_landing {
  .wrap_head_toogle {
    display: flex;
    padding: 15px;
    border-bottom: 1px solid #d9d9d9;
    justify-content: space-between;
    align-items: center;

    .wrap_heading {
      padding: unset;
      border: unset;
      width: 70%;
      @include break-point(miniTab) {
        width: 50%;
      }
    }
    .btn_toogle {
      display: flex;
      align-items: center;
      width: calc(100% - 70%);
      justify-content: end;
      @include break-point(miniTab) {
        width: calc(100% - 50%);
      }

      .cstm_span_requested {
        border: 1px solid $filter_input;
        max-width: 140px;
        width: 100%;
        min-height: 35px;
        text-align: center;
        line-height: 35px;
        font-weight: 600;
        cursor: pointer;
        transition: all 0.4s ease-in-out;
        &:hover {
          background-color: $btn_border-common;
          color: $white-color;
        }
        &.active {
          background-color: $btn_border-common;
          color: $white-color;
        }
      }
    }
  }
  .wrap_booking_heading {
    .wrap_faq_tabs {
      .nav-tabs {
        border: unset;
        @include break-point(mobile) {
          display: flex;
          width: 100%;
          overflow-y: scroll;
          flex-wrap: nowrap;
        }
        .nav-item {
          border: 1px solid #c7d2de;
          margin-right: 20px;
          .nav-link {
            color: $form_heading;
            font-size: 14px;
            border: unset;
            margin-bottom: unset;
            border-radius: unset;
            &.active {
              background-color: $btn_border-common;
              color: $white-color;
            }
          }
        }
      }
      .wrap_tabs_card {
        .row {
          .wrap_listing_card {
            max-width: unset;
            .recommend_list {
              display: flex;
              max-width: unset;
              cursor: pointer;
              @include break-point(mobile) {
                display: block;
              }
              figure {
                max-width: 220px;
                // height: 175px;
                @include break-point(mobile) {
                  max-width: 500px;
                  margin-bottom: 10px;
                }
              }
              .cstm_wrap_card_list {
                border-bottom: unset;
                padding: 0px 0px 0px 10px;
                width: 100%;
                p {
                  margin-bottom: 0;
                  font-size: 14px;
                  padding-bottom: 3px;
                  span {
                    font-size: 14px;
                    font-weight: 600;
                    padding-bottom: 3px;
                  }
                }
                .wrap_value {
                  justify-content: space-between;
                }
                .wrap_hosted {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  padding-top: 10px;
                  .wrap_fig_img {
                    display: flex;
                    align-items: center;
                    max-width: 65%;
                    width: 100%;
                    figure {
                      max-width: 30px;
                      width: 100%;
                      height: 30px;
                      border-radius: 50%;
                      overflow: hidden;
                      margin-right: 10px;
                    }
                    img {
                      height: 100%;
                      object-fit: fill;
                    }
                  }
                  &.wrap_hosted_overwrite {
                    @include break-point(mobile) {
                      display: block;
                      .wrap_fig_img {
                        max-width: 100%;
                        margin-bottom: 10px;
                        figure {
                          margin-bottom: 0;
                        }
                        span {
                          text-align: start;
                        }
                      }
                      span {
                        width: 100%;
                        text-align: center;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.wrap_image_property {
  background-color: $white-color;
  padding: 15px;
  .row {
    .wrap_property_section {
      .large_property {
        height: 307px;
        width: 100%;
        margin-bottom: 0;
        position: relative;
        cursor: pointer;
        img {
          height: 100%;
          object-fit: cover;
        }
        .tag_span {
          border: 1px solid rgba(239, 239, 239, 0.44);
          background-color: rgba(239, 239, 239, 0.44);
          padding: 5px;
          color: $white-color;
          position: absolute;
          bottom: 6px;
          right: 10px;
          cursor: pointer;
        }
      }
    }
  }
  &.propert_detailing_list {
    .row {
      .wrap_inner_card {
        .location {
          padding-top: 15px;
          .dflex {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .pending {
              color: #f4ae23;
              font-size: 16px;
              font-weight: 600;
            }
            .accepted {
              color: #5ec83a;
              font-size: 16px;
              font-weight: 600;
            }
            .rejected {
              color: #f85a5a;
              font-size: 16px;
              font-weight: 600;
            }
            .completed {
              font-size: 16px;
              font-weight: 600;
            }
            .ongoing {
              display: flex;
              align-items: center;
              color: $btn_border-common;
              font-size: 16px;
              font-weight: 600;
              img {
                width: 16px;
                height: 16px;
                margin-right: 5px;
              }
            }
            .Request_again {
              background-color: $btn_border-common;
              padding: 5px 8px;
              color: $white-color;
              font-size: 12px;
            }
          }
          .wrap_booking_rating {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .cstm_p {
              color: $btn_border-common;
              span {
                font-size: 16px;
              }
            }
            .rating_star {
              background-color: #f4ae23;
              font-weight: 700;
              color: $white-color;
              padding: 3px 5px;
              display: inline-flex;
              align-items: center;
              margin-left: 20px;
              svg {
                font-size: 11px;
                margin-right: 5px;
                margin-bottom: 1px;
              }
            }
          }

          h5 {
            font-weight: 600;
            color: $form_heading;
            font-size: 22px;
          }
          .location_tag {
            color: $form_heading;
            font-size: 14px;
            svg {
              margin-bottom: 3px;
              margin-right: 5px;
            }
          }
        }
        .amount {
          span {
            font-size: 14px;
            font-weight: 600;
            color: $form_heading;
          }
        }
      }
      .cstm_ul {
        border-top: 1px solid #d9d9d9;
        border-bottom: 1px solid #d9d9d9;
        padding: 12px 0px;
        margin-top: 15px;
        display: flex;
        justify-content: space-between;
        ul {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          li {
            padding-right: 20px;
            color: $form_heading;
            .hide {
              display: none;
              @include break-point(mobile) {
                display: inline-block;
              }
            }
            svg {
              font-size: 12px;
              margin-right: 10px;
              color: #c9c9c9;
            }
          }
        }
        .cstm_btn {
          border: 1px solid $profile_input_color;
          padding: 5px 10px;
          cursor: pointer;
          font-size: 14px;
          color: $form_heading;

          svg {
            font-size: 14px;
            margin-right: 10px;
            margin-bottom: 3px;
          }
        }
      }
      .about_property {
        padding: 20px 0px;
        h5 {
          font-size: 22px;
          font-weight: 600;
          color: $form_heading;
        }
        .clr {
          color: $crousal_para;
          a {
            text-decoration: underline;
            color: $crousal_para;
          }
        }
      }
      .wrap_overview {
        padding: 30px 0px;
        border-bottom: 1px solid $nav_border-color;
        h6 {
          font-weight: 600;
          font-size: 20px;
          margin-bottom: 15px;
        }
        .row {
          .wrap_over_card {
            p {
              font-weight: 600;
              margin-bottom: 0;
              font-size: 18px;
            }
            span {
              color: $crousal_para;
              font-size: 16px;
            }
            &.wrap_cstm_pdf {
              display: flex;
              align-items: center;
              padding-top: 15px;
              figure {
                max-width: 30px;
                margin-right: 10px;
              }
            }
            &.wrap_cstm_btn_pdf {
              .btn_see_more {
                color: $btn_border-common;
                border-color: #a9b5c2;
                position: relative;
                z-index: 1;
                transition: all 0.5s linear;
                &::before {
                  content: "";
                  height: 100%;
                  width: 0;
                  position: absolute;
                  background: $btn_border-common;
                  top: 0;
                  left: 0;
                  transition: all 0.5s linear;
                  z-index: -1;
                }
                &:hover {
                  &::before {
                    width: 100%;
                  }
                  color: $white-color;
                }
              }
            }
            &.wrap_cstm_btn_pdf {
              display: flex;
              justify-content: right;
              .cstm_anch_card_down{
                display: contents;
              }
            }
          }
        }
        &.contract_pdf{
          .row{
            align-items: center;
          }
        }
      }
      .wrap_facilities {
        .row {
          .wrap_over_card {
            .wrap_img_txt {
              display: flex;
              align-items: center;
              figure {
                width: 24px;
                margin-bottom: 0;
                margin-right: 10px;
              }
              span {
                color: $form_heading;
                font-size: 16px;
              }
            }
          }
        }
      }
      .wrap_ratings {
        .wrap_div {
          display: flex;
          align-items: center;
          justify-content: space-between;
          h6 {
            margin-bottom: 5px;
          }
          .location_tag {
            font-size: 14px;
            svg {
              margin-right: 10px;
              margin-bottom: 6px;
            }
          }
        }
        .row {
          margin-top: 15px;
          .wrap_review {
            border: 1px solid #e3e6e9;
            padding: 12px;
            margin-bottom: 20px;
            span {
              font-size: 14px;
              color: $crousal_para;
            }
            .wrap_review_user {
              display: flex;
              align-items: center;
              padding: 10px 0px;
              figure {
                width: 40px;
                height: 40px;
                overflow: hidden;
                border-radius: 50%;
                margin-bottom: 0;
                img {
                  height: 100%;
                  object-fit: fill;
                }
              }
              .wrap_review_star {
                padding-left: 20px;
                p {
                  font-weight: 600;
                  margin-bottom: 0px;
                }
                .wrap_star_dots {
                  display: flex;
                  align-items: center;
                  .star-ratings {
                    .star-container {
                      padding-right: 0px !important ;
                      svg {
                        width: 20px !important;
                        height: 20px !important;
                      }
                    }
                  }
                  span {
                    padding-left: 10px;
                    color: #768aa1;
                    font-size: 12px;
                    svg {
                      font-size: 8px;
                      color: #768aa1;
                    }
                  }
                }
              }
            }
          }
          .btn_see_wrapper {
            margin-top: 15px;
            .btn_see_more {
              max-width: 170px;
            }
          }
        }
      }
      .wrap_Things {
        .row {
          .wrap_things {
            p {
              font-weight: 600;
              margin-bottom: 10px;
              font-size: 18px;
            }
            span {
              font-size: 16px;
              margin-bottom: 20px;
              color: $crousal_para;
            }
            ul {
              list-style: disc;
              padding-left: 18px;
              li {
                color: $crousal_para;
              }
            }
          }
          .btn_see_wrapper {
            .btn_see_more {
              border-color: #a9b5c2;
            }
          }
        }
      }
    }
    .inner_chat_card {
      padding-left: 0px 0px 0px 10px;
      .card_chat {
        background-color: $btn_border-common;
        padding: 15px;
        margin-bottom: 15px;
        .wrap_chat_img {
          display: flex;
          align-items: center;
          figure {
            width: 60px;
            height: 60px;
            overflow: hidden;
            border-radius: 50%;
            margin-bottom: 0;
            img {
              height: 100%;
              object-fit: fill;
            }
          }
          .hosted {
            padding-left: 20px;
            p {
              color: $white-color;
              margin-bottom: 0;
            }
            h5 {
              color: $white-color;
              svg {
                margin-left: 10px;
                margin-bottom: 3px;
              }
            }
          }
        }
        .wrap_chat_btn {
          padding-top: 20px;
          .chat_now_btn {
            width: 100%;
            min-height: 40px;
            background-color: #ffffff;
            border: 1px solid #ffffff;
            font-weight: 400;
            font-size: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $btn_border-common;
            svg {
              margin-right: 10px;
              transform: rotateY(180deg);
            }
          }
        }
      }
      .lease {
        padding: 15px;
        border: 1px solid $profile_input_color;

        p {
          margin-bottom: 5px;
          font-weight: 600;
        }
        .wrap_label {
          padding-bottom: 20px;
          label {
            display: inline-block;
            padding: 0px;
            margin-right: 10px;
            cursor: pointer;
            max-width: 30%;
            width: 100%;
            padding-bottom: 15px;

            input {
              visibility: hidden;
              width: 0;
              height: 0;

              &:checked {
                & + span {
                  &::after {
                    opacity: 1;
                  }
                }
              }
            }
            span {
              position: relative;
              line-height: 16px;
              &::before {
                content: "";
                border: 1px solid $form_heading;
                width: 16px;
                height: 16px;
                margin-right: 10px;
                display: inline-block;
                vertical-align: top;
              }
              &::after {
                content: "";
                background: $form_heading;
                width: 8px;
                height: 8px;
                position: absolute;
                top: 4px;
                left: 4px;
                transition: 300ms;
                opacity: 0;
              }
            }
          }
        }
      }

      .btn_book_now {
        margin-top: 20px;
        .btn_see_more {
          background-color: $btn_border-common;
          border: 1px solid $btn_border-common;
          color: $white-color;
          max-width: 100%;
          width: 100%;
          font-weight: 600;
        }
      }
      &.wrap_booking_cancel {
        .wrap_message {
          background: rgba(63, 193, 201, 0.08);
          text-align: center;
          margin-bottom: 15px;
          padding: 15px;
          span {
            color: $btn_border-common;
          }
          &.wrap_request_message {
            background-color: rgba(39, 65, 94, 0.04);
            text-align: unset;
            p {
              margin-bottom: 5px;
              font-weight: 600;
              font-size: 18px;
            }
            span {
              color: $form_heading;
              font-size: 14px;
            }
            .wrap_star_dots {
              align-items: center;
              .star-ratings {
                .star-container {
                  padding-right: 0px !important ;
                  svg {
                    width: 30px !important;
                    height: 30px !important;
                  }
                }
              }
            }
          }
        }
        .lease {
          padding: 15px;
          border: unset;
          background-color: rgba(39, 65, 94, 0.04);
          .wrap_detail_amount {
            padding-top: 0;
            width: 100%;
            h5 {
              font-weight: 600;
              font-size: 18px;
            }
            ul {
              border-bottom: 1px solid $nav_border-color;
              li {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding-bottom: 8px;
                span {
                  font-size: 14px;
                }
              }
            }
            .total_amount {
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding-top: 10px;
              span {
                font-size: 14px;
              }
            }
          }
        }
        .btn_book_now {
          .btn_see_more {
            background-color: rgba(248, 90, 90, 0.08);
            border: 1px solid $btn_cancel;
            color: $btn_cancel;
          }
          .btn_request_cancel {
            background-color: $btn_border-common;
            border: 1px solid $btn_border-common;
            color: $white-color;
          }
          &.btn_flex_wrap {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .btn_pay_half {
              max-width: 48%;
              width: 100%;
            }
            .btn_request_cancel {
              max-width: 48%;
              width: 100%;
            }
          }
        }
      }
      &.wrap_payment_details {
        .lease {
          .wrap_detail_amount {
            .table {
              color: $form_heading;
              thead {
                tr {
                  background-color: #e5ebf1;
                }
              }
              tr {
                th {
                  color: $form_heading;
                  font-weight: 600;
                  background-color: transparent;
                  font-size: 14px;
                }
                td {
                  color: $form_heading;
                  background-color: transparent;
                  font-size: 13px;
                }
                .pending {
                  color: #f4ae23;
                }
                .paid {
                  color: #5ec83a;
                }
              }
            }
          }
        }
        a {
          width: 100%;
          .lease {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .wrap_message_dispute {
              display: flex;
              align-items: center;
              img {
                width: 25px;
                margin-right: 15px;
              }
              span {
                font-size: 14px;
                font-weight: 600;
                color: $form_heading;
              }
            }
            svg {
              font-size: 18px;
              color: $form_heading;
            }
          }
        }
      }
    }
  }
}
