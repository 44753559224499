@import "../../../scss/index.scss";

.wrap_helpandsupport {
  .form_profile_set {
    .cstm_map_wrap {
      width: 100%;
      height: 300px;
    }
  }
}
