@import "../../../scss/index.scss";

.wrap_faq {
  .head_setting {
    padding: unset !important;
    display: block !important;
    .wrap_faq_serach_bar {
      display: flex;
      align-items: center;
      min-height: 52px;
      padding: 0px 20px;
      justify-content: space-between;
      @include break-point(mobile) {
        display: block;
      }
      .wraptoogle_btn {
        display: flex;
        align-items: center;
        @include break-point(mobile) {
          padding: 10px 0px;
        }
      }

      .wrap_search {
        max-width: 50%;
        width: 100%;
        position: relative;

        @include break-point(mobile) {
          max-width: 100%;
          margin: 5px 0px;
        }
        svg {
          position: absolute;
          color: $form_heading;
          top: 12px;
          left: 10px;
          font-size: 12px;
        }
      }
    }
  }
  .form_profile_set {
    .wrap_faq_tabs {
      .nav-tabs {
        border: unset;
        @include break-point(mobile) {
          display: flex;
          white-space: nowrap;
          flex-wrap: nowrap;
          overflow-x: auto;
          width: 100%;
        }
        .nav-item {
          border: 1px solid #c7d2de;
          margin-right: 20px;
          .nav-link {
            color: $form_heading;
            font-size: 14px;
            border: unset;
            margin-bottom: unset;
            border-radius: unset;
            &.active {
              background-color: $btn_border-common;
              color: $white-color;
            }
          }
        }
      }
      .tab-content {
        .tab-pane {
          .wrap_accordian {
            .accordion {
              .accordion-item {
                border-bottom: unset;
                .accordion-header {
                  p {
                    font-weight: 600;
                    margin-bottom: 0;
                    margin-left: 20px;
                    letter-spacing: 0.5px;
                    color: $form_heading;
                    width: calc(100% - 20px);
                  }
                  .accordion-button {
                    background-color: $white-color;
                    box-shadow: unset;
                    figure {
                      width: 20px;
                    }
                    .addsign_accord {
                      display: none;
                    }
                    &.collapsed {
                      .addsign_accord {
                        display: block;
                      }
                      .minussign_accord {
                        display: none;
                      }
                    }
                    &::after {
                      display: none;
                    }
                    &:focus {
                      border: unset;
                      box-shadow: unset;
                    }
                  }
                }
                .accordion-collapse {
                  .accordion-body {
                    color: $crousal_para;
                    font-size: 14px;
                    padding-top: 5px;
                    ol {
                      padding-left: 58px;
                      li {
                        padding-bottom: 10px;
                      }
                    }
                    p {
                      padding-left: 38px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
