@import "./index.scss";

.wrapper_account {
  padding-bottom: 40px;
  padding-top: 20px;
  // background-color: rgba(63, 193, 201, 0.07);
  background-image: url(../assets/images/hero_section_bg.png);
  // background-repeat: no-repeat;
  // background-size: 100% 100%;
  .dashboard_wrap {
    display: flex;
    justify-content: space-between;
    // box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    background-color: $white-color;

    .dashbaord_content {
      width: calc(100% - 300px);
      @include break-point(miniTabs) {
        width: 100%;
      }
      .head_setting {
        padding: 15px 15px 15px 20px;
        border-bottom: 1px solid $nav_border-color;
        min-height: 53px;
        display: flex;
        align-items: center;
        h6 {
          margin-bottom: 0;
          font-size: 18px;
          color: $form_heading;
        }
        .cstm_bars {
          margin-right: 10px;
          display: none;
          @include break-point(miniTabs) {
            display: block;
          }
        }
      }
    }
  }
}
.wrapper_property_details {
  padding-bottom: 40px;
  padding-top: 20px;
  // background-color: rgba(63, 193, 201, 0.07);
  background-image: url(../assets/images/hero_section_bg.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  .wrapper_property {
  }
}

.erro_img {
  color: red !important;
  margin-left: 3px;
}
.innerside_toogle {
  overflow: hidden;
  .wrapper_account {
    .dashboard_wrap {
      .wrapper_accont_setting {
        left: 0;
        z-index: 5;
        background-color: $white-color;
      }
    }
  }
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #0000005e;
    top: 0;
  }
}

.cross_btn_hide {
  .modal-dialog {
    .modal-content {
      .modal-header {
        .btn-close {
          display: none;
        }
      }
    }
  }
}
.nav-link:focus-visible {
  box-shadow: unset;
}
.wrap_modal {
  &.payment_modal_save_card {
    .modal-dialog {
      max-width: 800px;
      width: 100%;
      @include break-point(miniTab) {
        max-width: unset;
      }
      .modal-content {
        .modal-body {
          form {
            label {
              width: 100%;
              padding-bottom: 20px;
              .StripeElement {
                width: 100%;
                background-color: $white-color;
                padding: 10px 20px 10px 20px;
                border: unset;
                font-size: 14px;
                border: 1px solid $profile_input_color;
                &.StripeElement--invalid {
                  border: 1px solid #f94848;
                }
              }
            }
            .cstm_width_pay {
              display: flex;
              align-items: center;
              justify-content: space-between;
              .label_1_pay {
                max-width: 70%;
                width: 100%;
              }
              .label_2_pay {
                max-width: calc(100% - 75%);
                width: 100%;
              }
            }
            .btn_see_more {
              background-color: #3fc1c9;
              border: 1px solid #3fc1c9;
              color: #ffffff;
              max-width: 100%;
              width: 100%;
              font-weight: 600;
            }
          }
          .row {
            &.cstm_padding {
              padding: 0px 20px;
              .cstm_col_label {
                @include break-point(miniTabs) {
                  margin-top: 20px;
                }
                form {
                  label {
                    font-size: 14px;
                    color: $crousal_para;
                    .cstm_input_profile {
                      &:focus {
                        border: 1px solid #e9e9e9;
                      }
                    }
                  }
                  .check_save_card {
                    display: flex;
                    align-items: center;
                    margin-bottom: 20px;
                    span {
                      margin-left: 10px;
                      color: $crousal_para;
                    }
                    .form-check-input {
                      &:checked {
                        background-color: $btn_border-common;
                        border-color: $btn_border-common;
                      }
                    }
                  }
                  .cstm_set_btn {
                    display: flex;
                    justify-content: right;
                  }
                }
              }
              .cstm_stripe_p {
                font-weight: 600;
                color: $form_heading;
              }
              .wrap_save_cards {
                // height: 50vh;
                // overflow-y: scroll;
                // @include scroll(y);

                .inner_cards {
                  display: flex;
                  align-items: baseline;
                  padding-bottom: 15px;
                  border-bottom: 1px solid $nav_border-color;
                  .cstm_space {
                    margin-left: 10px;
                    font-size: 16px;
                    span {
                      font-size: 16px;
                    }
                  }
                  .cstm_input_profile {
                    margin: 10px 10px 10px 10px;
                    max-width: 120px;
                    display: block;
                  }
                  &:not(:first-child) {
                    margin-top: 20px;
                  }
                  &:last-child {
                    border-bottom: unset;
                  }
                  .radio_cstm[type="radio"] {
                    accent-color: #232323;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
.cstm_wrap_location {
  .txt_center {
    display: flex;
    justify-content: center;
  }
}

.cstm_unfill_heart {
  color: $white-color;
}
.cstm_fill_heart {
  color: #f94848;
}

.card_copy_details {
  .row {
    .wraper_card_details {
      background-color: #f6f6f6;
      margin-top: 30px;
      padding: 20px 30px;
      border-radius: 18px;
      .user_name {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 15px;
        svg {
          font-size: 22px;
          cursor: pointer;
        }
      }
      .card_number {
        padding-bottom: 15px;
        h1 {
          font-weight: 400;
        }
      }
      .wrapper_expiry_date {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .cvv_expiry {
          display: flex;
          align-items: center;
          justify-content: space-between;
          max-width: 40%;
          width: 100%;

          .cstm_expiry {
            p {
              margin-bottom: 0;
            }
            h4 {
              margin-bottom: 0;
            }
          }
          .cstm_cvv {
            p {
              margin-bottom: 0;
            }
            h4 {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}

.cstm_no_property {
  display: flex;
  align-items: center;
  justify-content: center;
  .nav_btn_cstms {
    width: 100%;
    max-width: 158px;
    min-height: 35px;
  }
}

.wrapper-static_terms {
  .wrap_static_terms {
    .tittle {
      text-align: center;
      padding: 40px 0px 80px 0px;
      background: #dfefe5 0% 0% no-repeat padding-box;
      box-shadow: 0px 6px 40px #0906370f;
    }
  }
  .wrap_content {
    width: 100%;
    position: relative;
    .wrap_terms_content {
      background: #ffffff 0% 0% no-repeat padding-box;
      border-radius: 32px;
      width: 100%;
      max-width: 90%;
      margin: 0 auto;
      padding: 45px;
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      margin-top: -45px;

      @include break-point(mobilestart) {
        padding: 45px 20px;
        max-width: 100%;
      }

      .wrap_txt {
        padding-bottom: 10px;
        .cstm_alpha {
          list-style-type: lower-alpha !important;
          padding-left: 40px;
        }
        .number_order {
          list-style-type: decimal !important;
          padding-left: 40px;
        }
        .cstm_list_dots {
          list-style-type: inherit !important;
          padding-left: 40px;
        }
        a {
          text-decoration: underline;
          word-break: break-all;
          color: #3fc1c9;
        }
      }

      h5 {
        font-weight: 700;
      }
      h6 {
        font-weight: 700;
      }
    }
  }
}
