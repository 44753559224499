@import "../../scss/index.scss";

.wrap_modal {
  .modal-dialog {
    .modal-content {
      border-radius: 0;
      .modal-header {
        .modal-title {
          font-size: 22px;
          color: $form_heading;
          margin-bottom: 0;
        }
      }
      .modal-body {
        .row {
          .modal_left_fig {
            max-width: 150px;
            width: 100%;
            @include break-point(mobile){
              margin: 0 auto;
              margin-bottom: 10px;
            }
          }
          .wrap_modal_txt {
            h3 {
              font-size: 22px;
              color: $form_heading;
              margin-bottom: 0;
            }
            span {
              color: #7189a4;
            }
            .cstm_btn {
              padding: 5px 15px;
              background-color: #3fc1c9;
              border: 1px solid #3fc1c9;
              color: #ffffff;
              font-weight: 400;
              font-size: 14px;
              width: 100%;
              margin: 10px 0px;
              img{
                width: 20px;
              }
            }
            a {
              color: $form_heading;
              text-decoration: underline;
              font-size: 13px;
              font-weight: 600;
            }
            .wrap_form_fields {
              label {
                font-size: 15px;
                color: #26405d;
                margin-bottom: 8px;
                font-weight: 600;
              }
              .cstm_input {
                border: 1px solid $crousal_btn_border;
              }
            }
          }
        }
      }
      .modal-footer {
        border: 0;
      }
    }
  }
  &.logout_modal_cstm{
    .modal-header{
      justify-content: space-between;
    }
  }
}
