@import "../../../scss/index.scss";

.wrapper_nodata_found {
  text-align: center;
  figure {
    max-width: 600px;
    width: 100%;
    margin: 0 auto;
  }
}
