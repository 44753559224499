@import "../../../scss/index.scss";

.footer {
  background-color: $footer_background;
  padding: 40px 0px 20px 0px;
  .wrap_footer {
    .row {
      .footer_card_wrap {
        // padding: 0px 40px;
        @include break-point(miniTabs) {
          padding: 10px 0px 10px 0px;
        }
        .logo_foot {
          max-width: 120px;
          width: 100%;
          img {
            width: 100%;
          }
        }
        p {
          color: $white-color;
          font-size: 15px;
        }
        .cstm_p {
          font-size: 18px;
          font-weight: 600;
          margin-bottom: 10px;
        }
        .span_cstm {
          width: 60%;
          height: 2px;
          background-color: $btn_border-common;

          &::before {
            content: "";
            width: 50%;
            height: 6px;
            display: block;
            position: relative;
            top: -2px;
            background-color: $btn_border-common;
          }
        }
        .social_icons_wrap {
          img {
            width: 37px;
          }
        }
        ul {
          margin-top: 15px;
          li {
            font-size: 13px;
            padding-bottom: 8px;
            color: $white-color;

            a {
              color: $white-color;
              text-decoration: unset;
            }
            svg {
              margin-right: 5px;
            }
            .wrap_gallery {
              max-width: 210px;
              width: 100%;
            }
          }
        }
      }
    }
  }
}
