@import "../scss/index.scss";

.dashbaord_content {
  .head_setting {
    padding: 15px;
    border-bottom: 1px solid $nav_border-color;
  }
  .wrap_profile_details {
    .form_profile_set {
      padding: 20px 20px;
      .cstm_color {
        color: $form_heading;
        font-size: 14px;
      }
      .user_image {
        position: relative;
        max-width: 100px;
        width: 100%;

        .cstm_profile_img {
          visibility: hidden;
          width: 0;
          height: 0;
          display: none;
        }
      }
      .img_profile {
        max-width: 100px;
        height: 100px;
        width: 100%;
        overflow: hidden;
        border-radius: 50%;

        img {
          height: 100%;
          object-fit: cover;
        }
      }
      .img_cstm_prof {
        max-width: 40px;
        margin-bottom: 0;
        position: absolute;
        bottom: 0px;
        right: 25%;
        transform: translateX(75%);
        cursor: pointer;
      }
      form {
        .row {
          .wrap_form_fields {
            margin-top: 15px;
            margin-bottom: 0px;
            label {
              font-size: 15px;
              color: $crousal_para;
              margin-bottom: 8px;
              font-weight: 400;
            }
          }
        }
      }
      &.wrap_privacy_policy {
        .cstm_color_second {
          color: $btn_border-common;
        }
        h6 {
          color: $form_heading;
          font-weight: 600;
        }
        .cstm_P_privacy {
          color: $crousal_para;
        }
      }
      &.cstm_wrap_dynamic_data {
        .scroll_cstm_set {
          height: 100vh;
          overflow-y: scroll;

          #logo {
            max-width: 150px;
            width: 100%;
            margin-top: 0px;
            margin-bottom: 30px;
          }
          h3 {
            font-size: 1.6rem;
            text-align: left;
          }
          h2 {
            font-size: 1.6rem;
            text-align: left;
          }
          h5{
            font-size: 16px;
          }
          .paragraph-container {
            h4 {
              font-size: 22px;
            }
          }
          .row-container {
            display: block;
            .image-container {
              margin-bottom: 20px;
              padding-left: 0px;
            }
            .content-container{
              padding-left: 0px;
            }
          }
          #mid-image {
            max-width: 1080px;
            width: 100%;
            margin: 0 auto;
          }
        }
      }
    }
  }
}
