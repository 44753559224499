@import "./index.scss";

@font-face {
  font-family: "Graphie";
  src: url("../assets/fonts/Graphie-Bold.woff2") format("woff2"),
    url("../assets/fonts/Graphie-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Graphie";
  src: url("../assets/fonts/Graphie-BoldItalic.woff2") format("woff2"),
    url("../assets/fonts/Graphie-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Graphie Book";
  src: url("../assets/fonts/Graphie-BookItalic.woff2") format("woff2"),
    url("../assets/fonts/Graphie-BookItalic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Graphie";
  src: url("../assets/fonts/Graphie-ExtraBold.woff2") format("woff2"),
    url("../assets/fonts/Graphie-ExtraBold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Graphie Book";
  src: url("../assets/fonts/Graphie-Book.woff2") format("woff2"),
    url("../assets/fonts/Graphie-Book.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Graphie";
  src: url("../assets/fonts/Graphie-ExtraLight.woff2") format("woff2"),
    url("../assets/fonts/Graphie-ExtraLight.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Graphie";
  src: url("../assets/fonts/Graphie-ExtraBoldItalic.woff2") format("woff2"),
    url("../assets/fonts/Graphie-ExtraBoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Graphie";
  src: url("../assets/fonts/Graphie-Italic.woff2") format("woff2"),
    url("../assets/fonts/Graphie-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Graphie";
  src: url("../assets/fonts/Graphie-LightItalic.woff2") format("woff2"),
    url("../assets/fonts/Graphie-LightItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Graphie";
  src: url("../assets/fonts/Graphie-Light.woff2") format("woff2"),
    url("../assets/fonts/Graphie-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Graphie";
  src: url("../assets/fonts/Graphie-ExtraLightItalic.woff2") format("woff2"),
    url("../assets/fonts/Graphie-ExtraLightItalic.woff") format("woff");
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Graphie";
  src: url("../assets/fonts/Graphie-Thin.woff2") format("woff2"),
    url("../assets/fonts/Graphie-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Graphie";
  src: url("../assets/fonts/Graphie-Regular.woff2") format("woff2"),
    url("../assets/fonts/Graphie-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Graphie";
  src: url("../assets/fonts/Graphie-SemiBoldItalic.woff2") format("woff2"),
    url("../assets/fonts/Graphie-SemiBoldItalic.woff") format("woff");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Graphie";
  src: url("../assets/fonts/Graphie-SemiBold.woff2") format("woff2"),
    url("../assets/fonts/Graphie-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Graphie";
  src: url("../assets/fonts/Graphie-ThinItalic.woff2") format("woff2"),
    url("../assets/fonts/Graphie-ThinItalic.woff") format("woff");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Graphie";
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.01em;
  color: $form_heading;
}
body.removeBodyCss {
  font-family: "Graphie";
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.01em;
  color: $form_heading;
  margin: unset;
}

a {
  display: inline-block;
  @include transition;
  text-decoration: none;
}

span {
  display: inline-block;
  font-size: 13px;
}

p {
  margin-bottom: 15px;
}

ul {
  list-style: none;
  margin: 0;
  padding-left: 0;
}
img {
  width: 100%;
}
.cstm_input {
  width: 100%;
  background-color: $white-color;
  padding: 10px 30px 10px 20px;
  border: unset;
  font-size: 14px;
  border: 1px solid $white-color;
  &:focus {
    outline: unset;
    border: 1px solid $btn_border-common;
  }
  &::placeholder {
    font-size: 13px;
    color: $crousal_para;
  }
}
.cstm_input_profile {
  width: 100%;
  background-color: $white-color;
  padding: 10px 20px 10px 20px;
  border: unset;
  font-size: 14px;
  border: 1px solid $profile_input_color;

  &:focus {
    outline: unset;
    border: 1px solid $btn_border-common;
  }
  &::placeholder {
    font-size: 13px;
    color: $crousal_para;
  }
}
.cstm_filter_input {
  border: 1px solid $filter_input;
  border-radius: 0;
  padding: 10px 15px 10px 30px;
  &:focus {
    outline: unset;
    border-color: $filter_input !important;
  }
}
.form_sbmt_btn {
  padding: 10px 20px;
  border: unset;
  background-color: $form_heading;
  font-size: 14px;
  color: $white-color;
  width: 100%;
  img {
    width: 20px;
  }
}
.form_sbmt_btn_light {
  padding: 10px 20px;
  border: unset;
  background-color: $btn_border-common;
  font-size: 14px;
  color: $white-color;
  width: 100%;
}
.form-check-input {
  background-color: #ffffff;
  border: var(--bs-border-width) solid #b1c1d4;
}
.form-check-input[type="checkbox"] {
  border-radius: 0;
}
.form-check-input:checked {
  background-color: $form_heading;
  border-color: $form_heading;
}

.form-check-input:focus {
  border-color: $form_heading;
  outline: 0;
  box-shadow: unset;
}
.common_btn {
  padding: 7px 10px;
  background-color: #3fc1c9;
  border: 1px solid #3fc1c9;
  color: #ffffff;
  font-weight: 600;
  font-size: 14px;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: 700;
  line-height: 1.4;
  margin-bottom: 5px;
}

h3 {
  font-size: 30px;

  @include break-point(tablet) {
    font-size: 26px;
  }
}

h4 {
  font-size: 26px;

  @include break-point(mobile) {
    font-size: 20px;
  }

  @include break-point(tablet) {
    font-size: 22px;
  }
}

h5 {
  @include break-point(tablet) {
    font-size: 16px;
  }
}

.Custom_container {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 15px;
  @include break-point(xllgdesktop) {
    max-width: 1780px;
  }
  @include break-point(smlgdesktop) {
    max-width: 1580px;
  }
  @include break-point(lgmediumdesktop) {
    max-width: 1366px;
  }
  @include break-point(desktop) {
    max-width: 1240px;
  }
}

.nav_btn_cstms {
  width: 100%;
  max-width: 130px;
  min-height: 50px;
  background-color: $btn_border-common;
  border: 1px solid $btn_border-common;
  color: #ffffff;
  font-weight: 600;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.nav_read_more {
  width: 100%;
  max-width: 130px;
  min-height: 40px;
  background-color: $crousal_heading;
  border: 1px solid $crousal_heading;
  color: #ffffff;
  font-weight: 600;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 15px 40px -15px #0e2a3e;

  svg {
    font-size: 28px;
  }
}
.padding_zero {
  padding: 0;
}
.padding-right_0 {
  padding-right: 0;
}
.btn_see_more {
  width: 100%;
  max-width: 130px;
  min-height: 40px;
  background-color: $white-color;
  border: 1px solid $form_heading;
  color: #ffffff;
  font-weight: 400;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $form_heading;
}
.cstm_select_btn {
  border-radius: unset;
  font-weight: 600;
  font-size: 16px;
}
.cstm_free_plan {
  background-color: #d7d7d7 !important;
  border-color: #d7d7d7 !important;
}
.form-select:focus {
  border-color: unset;
  outline: 0;
  box-shadow: unset;
}
.god_cstm_fill {
  font-size: 9px;
  color: #d5dbe2;
}

.wrap_listing_card {
  color: $crousal_para;
  .recommend_list {
    border: 1px solid #1d1e201f;
    transition: all 0.5s ease-in-out;
    max-width: 520px;
    width: 100%;
    &:hover {
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      .slick-slider {
        figure {
          img {
            transform: scale(1.1);
          }
        }
      }
    }
    figure {
      width: 100%;
      margin-bottom: 0;
      height: 165px;
      position: relative;
      overflow: hidden;
      a {
        width: 100%;
        height: 100%;
        img {
          width: 100%;
          height: 100%;
          transform: scale(1);
          transition: all 0.3s ease-in-out;
        }
      }
      span {
        color: #fff;
        left: 10px;
        bottom: 5px;
        position: absolute;
        background: rgba(0, 0, 0, 0.54);
        padding: 0px 5px;
      }
      .cstm_unfill {
        color: $white-color;
        right: 10px;
        top: 5px;
        position: absolute;
      }
      .cstm_fill {
        color: #f94848;
        right: 10px;
        top: 5px;
        position: absolute;
      }
      .cstm_unfill_red {
        color: red;
        right: 10px;
        top: 5px;
        position: absolute;
        border-color: $white-color;
      }
    }
    .cstm_wrap_card_list {
      padding: 5px 8px;
      .wrap_value {
        display: flex;
        align-items: center;
        justify-content: space-between;

        b {
          color: $form_heading;
          span {
            font-weight: 400;
          }
        }
        .wrap_rating {
          background-color: #f4ae23;
          color: $white-color;
          padding: 0px 5px;
          font-weight: 600;
          svg {
            font-size: 9px;
            margin-right: 3px;
            margin-bottom: 2px;
          }
        }
      }
      .dot_cstm_fill {
        color: #d5dbe2;
        font-size: 10px;
      }
      .property_location {
        display: block;
      }
      .wrap_dealer_name {
        display: flex;
        align-items: center;
        padding-top: 5px;
        padding-bottom: 2px;
        figure {
          border-radius: 50%;
          height: 40px;
          width: 40px;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
            object-fit: fill;
          }
        }
        .name_wrap {
          padding-left: 10px;
          p {
            margin-bottom: 0;
            color: $form_heading;
          }
        }
      }
    }
    .slick-list {
      .slick-track {
        .slick-slide {
          & > div {
            padding: 0px 0px 0px 0px !important;
          }
        }
      }
    }
    .slick-dots {
      bottom: 10px;
      overflow: hidden;
      white-space: nowrap;
      li {
        width: 0px;
        height: 0px;
        margin: 0 6px;
        button {
          &::before {
            color: $white-color;
            opacity: 0.8;
            border: 1px solid $white-color;
            content: "";
            border-radius: 50%;
            width: 7px;
            height: 7px;
          }
          &:hover {
            &::before {
              color: $white-color;
              background-color: $white-color;
              opacity: 1;
            }
          }
        }
      }
      .slick-active {
        button {
          &::before {
            color: $white-color;
            border: 1px solid $white-color;
            background-color: $white-color;
            opacity: 1;
            content: "";
            border-radius: 50%;
            width: 7px;
            height: 7px;
          }
        }
      }
    }
  }
}

.slick-arrow {
  display: none !important;
}

.font_size_24px {
  font-size: 16px;
  margin-bottom: 0;
  font-weight: 400;
}
.font_size_16px {
  font-size: 12px;
  font-weight: 400;
}
.cstm_filter_span {
  border: 1px solid $filter_input;
  text-align: center;
  margin-right: 5px;
  max-width: 50px;
  width: 100%;
  padding: 5px 0px;
  cursor: pointer;
  margin-bottom: 5px;
  color: $form_heading;
  &.active {
    background-color: $btn_border-common;
    color: $white-color;
    border-color: $btn_border-common;
  }
}
// input[type="number"]::-webkit-inner-spin-button,
// input[type="number"]::-webkit-outer-spin-button {
//   -webkit-appearance: none;
//   -moz-appearance: none;
//   appearance: none;
//   margin: 0;
// }
.dark_cstm_skeleton {
  max-width: 365px;
  width: 100%;
}
.shimmer-container {
  background-color: #ffffff;

  .shimmer {
    width: 100%;
    height: 100%;
    background: linear-gradient(
      90deg,
      transparent 25%,
      #f0f0f0 50%,
      transparent 75%
    );
    background-size: 200% 100%;
    animation: shimmer 1.5s infinite;
  }
}

@keyframes shimmer {
  0% {
    background-position: -200% 0;
  }

  100% {
    background-position: 200% 0;
  }
}
.btn_booking_details {
  width: 100%;
  max-width: 100px;
  min-height: 35px;
  background-color: $white-color;
  border: 1px solid #e6e7eb;
  font-weight: 400;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $btn_border-common;
}
.position_set_pagination {
  position: relative;
  .wrap_pagination {
    display: flex;
    position: absolute;
    bottom: -30px;
    width: 100%;
    @include break-point(mobilestart) {
      display: block;
      position: unset;
      bottom: unset;
      width: 100%;
      text-align: center;
    }
    .wrap_input_no {
      display: flex;
      align-items: center;
      max-width: 58%;
      width: 100%;
      justify-content: end;
      @include break-point(lscapeTablet) {
        justify-content: start;
        max-width: 25%;
      }
      @include break-point(mobilestart) {
        display: block;
        max-width: 100%;
        justify-content: unset;
      }
      .wrap_input_pagination {
        border: 1px solid $form_heading;
        max-width: 50px;
        border-radius: 4px;
        width: 100%;
        padding: 2px 5px;
        font-size: 12px;
        &:focus {
          outline: unset;
        }
      }
    }
    .wrap_next_prev {
      max-width: 42%;
      width: 100%;
      display: flex;
      justify-content: end;
      @include break-point(lscapeTablet) {
        max-width: 75%;
      }
      @include break-point(mobilestart) {
        margin-top: 20px;
        max-width: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
      }
      .btn_see_more_pagination {
        background-color: $white-color;
        border: 1px solid $form_heading;
        font-weight: 400;
        min-height: 35px;
        font-size: 14px;
        display: flex;
        align-items: center;
        padding: 0px 20px;
        color: $form_heading;
        svg {
          margin-right: 10px;
          margin-bottom: 1px;
          color: $form_heading;
          font-size: 22px;
        }
        &.next_btn {
          svg {
            margin-right: 0;
            margin-left: 10px;
            font-size: 22px;
          }
        }
      }
    }
  }
}
.pending {
  color: #f4ae23;
  font-size: 14px;
  font-weight: 600;
}
.accepted {
  color: #5ec83a !important;
  font-size: 14px;
  font-weight: 600;
}
.rejected {
  color: #f85a5a;
  font-size: 14px;
  font-weight: 600;
}
.cancelled {
  color: #f85a5a;
  font-size: 14px;
  font-weight: 600;
}
.ongoing {
  display: flex;
  align-items: center;
  color: $btn_border-common;
  font-size: 14px;
  font-weight: 600;
  img {
    width: 15px;
    height: 15px;
    margin-right: 5px;
  }
}
.Request_again {
  background-color: $btn_border-common;
  padding: 5px 8px;
  color: $white-color;
  font-size: 12px;
  cursor: pointer;
}
.cstm_search_bar {
  width: 100%;
  background-color: #f6f6f6;
  padding: 7px 20px 7px 30px;
  // border: unset;
  font-size: 14px;
  border: unset;
  &:focus {
    outline: unset;
  }
}
input {
  color: $form_heading;
}
select {
  color: #27415e !important;
}

.wrap_modal_review {
  .modal-dialog {
    .modal-content {
      border-radius: 0;
      .modal-header {
        justify-content: space-between;
        border: 0;
        .modal-title {
          width: 100%;
          text-align: center;
          font-size: 20px;
          font-weight: 600;
        }
        button {
          border: 1px solid $form_heading !important;
          margin: 0;
          border-radius: unset;
          color: $form_heading;
          font-size: 12px;
          &:focus {
            box-shadow: unset;
          }
        }
      }
      .modal-body {
        padding-top: 0;
        .wrap_review {
          .wrap_star_dots {
            text-align: center;
            .star-ratings {
              .star-container {
                svg {
                  width: 30px !important;
                  height: 30px !important;
                }
              }
            }
          }
          form {
            max-width: 350px;
            width: 100%;
            margin: 0 auto;
            .wrap_textarea {
              padding-top: 15px;
              p {
                margin-bottom: 0;
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-size: 16px;
              }
              textarea {
                &::placeholder {
                  color: $form_heading;
                }
              }
            }
          }
        }
      }
      .modal-footer {
        border: 0;
      }
    }
  }
}
.wrap_modal_dispute {
  .modal-dialog {
    max-width: 840px;
    .modal-content {
      border-radius: 0;
      .modal-header {
        .modal-title {
          font-size: 20px;
          font-weight: 600;
        }
        button {
          border: 1px solid $form_heading !important;
          margin: 0;
          border-radius: unset;
          color: $form_heading;
          font-size: 12px;
          &:focus {
            box-shadow: unset;
          }
        }
      }
      .modal-body {
        .wrap_dispute {
          .row {
            .wrap_card {
              label {
                padding-bottom: 5px;
              }
              select {
                color: $form_heading;
                &:focus {
                  outline: unset;
                  border-color: #e9e9e9;
                }
              }
            }
            .wrap_textarea {
              padding-top: 10px;
              label {
                padding-bottom: 5px;
                display: flex;
                align-items: center;
                width: 100%;
                justify-content: space-between;
              }
              textarea {
                color: $form_heading;
                &::placeholder {
                  color: $form_heading;
                }
              }
            }
            .wrap_card_upload {
              position: relative;
              height: 100%;
              label {
                b {
                  font-weight: 600;
                  span {
                    color: #59718b;
                  }
                }
              }
              .block {
                display: block;
                color: #59718b;
                padding-bottom: 10px;
              }
              .wrap_upload {
                display: flex;
                align-items: center;
                border: 2px dotted rgba(39, 65, 94, 0.06);
                background-color: rgba(39, 65, 94, 0.06);
                padding: 5px 20px;
                figure {
                  width: 30px;
                  margin-bottom: 0;
                }
                input {
                  width: 0;
                  visibility: hidden;
                  opacity: 0;
                  height: 0;
                }
                span {
                  display: block;
                }
              }
              .wrap_btn_sub {
                .cstm_btn {
                  min-height: 35px;
                  max-width: 300px;
                  width: 100%;
                  position: absolute;
                  bottom: 0;
                  right: 0;
                  background-color: $btn_border-common;
                  color: $white-color;
                  border: 1px solid $btn_border-common;
                }
              }
              .data_type {
                padding-left: 20px;
              }
            }
          }
        }
      }
      .modal-footer {
        border: 0;
      }
    }
  }
}
.wrap_modal {
  .modal-dialog {
    .modal-content {
      border-radius: 0;
      .modal-header {
        .modal-title {
          font-size: 20px;
          font-weight: 600;
        }
        button {
          border: 1px solid $form_heading !important;
          margin: 0;
          border-radius: unset;
          color: $form_heading;
          font-size: 12px;
          &:focus {
            box-shadow: unset;
          }
        }
      }
      .lease {
        padding: 0px;
        // border: 1px solid $profile_input_color;

        p {
          margin-bottom: 5px;
          font-weight: 600;
        }
        .wrap_label {
          padding-bottom: 0px;
          label {
            display: inline-block;
            padding: 0px;
            margin-right: 10px;
            cursor: pointer;
            max-width: 30%;
            width: 100%;
            padding-bottom: 15px;

            @include break-point(mobile) {
              max-width: 45%;
            }

            input {
              visibility: hidden;
              width: 0;
              height: 0;

              &:checked {
                & + span {
                  &::after {
                    opacity: 1;
                  }
                }
              }
            }
            span {
              position: relative;
              line-height: 16px;
              &::before {
                content: "";
                border: 1px solid $form_heading;
                width: 16px;
                height: 16px;
                margin-right: 10px;
                display: inline-block;
                vertical-align: top;
              }
              &::after {
                content: "";
                background: $form_heading;
                width: 8px;
                height: 8px;
                position: absolute;
                top: 4px;
                left: 4px;
                transition: 300ms;
                opacity: 0;
              }
            }
          }
        }
        .btn_book_now {
          margin-top: 15px;
          .btn_see_more {
            margin: 0 auto;
          }
        }
      }
    }
  }
}

.parent_wrap_crousal {
  .slick-slider {
    .slick-list {
      .slick-track {
        margin-left: unset;
        margin-right: unset;
      }
    }
  }
}
.width_full_shimmer {
  max-width: 100% !important;
}
.cstm_errors {
  border: 1px solid red;
}
.cstm_error_clr {
  color: red;
}
.wrap_datePicker {
  position: relative;
  .react-datepicker-wrapper {
    width: 100%;
  }
  .calendar_icon {
    position: absolute;
    right: 20px;
    top: 50%;
    img {
      width: unset;
      cursor: pointer;
    }
  }
}
