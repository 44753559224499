@import "../../scss/index.scss";

.wrapper_wishlist {
  .cstm_row_whislist {
    align-items: center;
    .wrap_heading {
      @include break-point(miniTabs) {
        margin-bottom: 10px;
      }
      h5 {
        font-weight: 600;
        margin-bottom: 0;
      }
    }
    .wrap_search_bar {
      position: relative;
      display: flex;
      align-items: center;
      background-color: $white-color;
      padding: 2px;
      border: 1px solid $filter_input;
      .cstm_input {
        padding: 7px 30px 7px 20px;
        &:focus{
          border-color: $white-color;
        }
      }
      .nav_btn_cstms {
        min-height: 37px;
        svg {
          font-size: 14px;
          margin-right: 5px;
        }
      }
      .cstm_nav_icon {
        font-size: 12px;
        position: absolute;
        left: 6px;
      }
    }
  }
  .row {
    .wrap_listing_card {
      margin-bottom: 20px;
    }
  }
}
