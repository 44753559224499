@import "../../../scss/index.scss";
header {
  .wrapper_header {
    // background-color: rgba(63, 193, 201, 0.07);
    border-bottom: 1px solid #f3f3f3;

    .bg-body-tertiary {
      background-color: unset !important;
      margin-bottom: unset !important;
      .navbar-toggler {
        &:focus {
          box-shadow: unset;
        }
      }

      .offcanvas {
        .offcanvas-body {
          .navbar-nav {
            .nav-link {
              font-weight: 400;
              font-size: 14px;
              color: $nav-text-color;
              margin-right: 25px;
            }
          }
          .wrapper_nav_btn {
            .nav_btn {
              padding: 5px 10px;
              border: 1px solid $btn_border-1;
              background-color: unset;
              color: $nav-text-color;
              font-weight: 600;
              font-size: 14px;
            }
            .nav_btn_cstm {
              padding: 5px 15px;
              background-color: $btn_border-common;
              border: 1px solid $btn_border-common;
              color: $white-color;
              font-weight: 600;
              font-size: 14px;
            }
          }
        }
      }
    }
    &.wrapper_user_header {
      background-image: url(../../../assets/images/header_bg.png);
      background-repeat: no-repeat;
      background-size: 100% 100%;
      .navbar {
        .offcanvas {
          .offcanvas-body {
            .navbar-nav {
              .nav-link {
                margin-right: 0px;
                svg {
                  font-size: 22px;
                  margin-right: 5px;
                }
              }
            }
            .dropdown {
              max-width: 180px;
              width: 100%;
              justify-content: space-between;
              display: flex;
              a {
                display: flex;
                align-items: center;
                justify-content: end;
                color: $nav-text-color;
                width: 100%;
                svg {
                  // position: absolute;
                  // right: 0px;
                  font-size: 30px;
                }
                figure {
                  margin-bottom: 0;
                  width: 35px;
                  height: 35px;
                  border-radius: 50%;
                  overflow: hidden;
                  margin-right: 10px;
                  img {
                    height: 100%;
                  }
                }
                span {
                  font-size: 14px;
                }
              }
              .cstm_drop_anchor {
                flex-direction: row-reverse;
                .drop_fig {
                  display: flex;
                  align-items: center;
                }
              }
              &.show {
                a {
                  & svg {
                    transform: rotate(180deg);
                  }
                }
              }
              .dropdown-menu {
                padding: 10px 0px 0px 0px;
                overflow: hidden;
                .dropdown-item {
                  justify-content: start;
                  padding-bottom: 10px;
                  padding-top: 5px;
                  .wrap_fig_drop {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    justify-content: center;
                    .wrap_user_drop {
                      p {
                        margin-bottom: 0;
                      }
                      span {
                        font-size: 12px;
                      }
                    }
                    p {
                      margin-bottom: 0;
                      font-size: 12px;
                    }
                    svg {
                      position: unset;
                      transform: unset;
                      font-size: 18px;
                      margin-right: 10px;
                    }
                    a {
                      justify-content: start;
                    }
                  }
                  &:active {
                    background-color: #fff;
                  }
                }
                .cstm_Drop {
                  padding-bottom: 10px;
                  border-bottom: 1px solid #d9d9d9;
                  .wrap_fig_drop {
                    p {
                      margin-bottom: 0;
                      font-size: unset;
                      font-size: 14px;
                    }
                  }
                }
                .log_out {
                  padding: 10px 0px;
                  border-top: 1px solid #d9d9d9;
                  text-align: center;
                  justify-content: center;
                }
              }
              &.cstm_dropdown {
                max-width: 130px;
                width: 100%;
                margin-right: 20px;
                a {
                  svg {
                    display: none;
                  }
                  .cstm_bell {
                    position: unset;
                    display: block;
                    font-size: 20px;
                    margin-right: 5px;
                    transform: unset;
                  }
                }
                .dropdown-menu {
                  min-width: 320px;
                  width: 100%;
                  padding-top: 0;
                  .cstm_Drop {
                    padding: 0;
                    .wrap_fig_drop {
                      display: flex;
                      justify-content: space-between;
                      width: 100%;
                      align-items: center;
                      padding: 10px 15px;
                      h6 {
                        margin-bottom: 0;
                        color: $crousal_heading;
                        font-weight: 600;
                        font-size: 22px;
                      }
                      span {
                        color: $btn_border-common;
                        font-size: 12px;
                        cursor: pointer;
                      }
                    }
                  }
                  .today_notification {
                    padding: 10px 15px;
                    height: 50vh;
                    overflow-y: scroll;
                    @include scroll(y);
                    .heading_p {
                      margin-bottom: 10px;
                      color: $location;
                      font-size: 14px;
                    }
                    a {
                      justify-content: start;
                      .wrap_noti_card {
                        display: flex;
                        margin-bottom: 15px;
                        padding: 3px 5px;
                        transition: all 0.5s ease-in-out;
                        width: 100%;

                        figure {
                          width: 50px;
                          height: 50px;
                          overflow: hidden;
                          border-radius: 50%;
                          background-color: #e0dada30;
                          margin-right: 7px;
                          img {
                            object-fit: none;
                          }
                        }
                        .noti_with_time {
                          border-bottom: 1px solid #d9d9d9;
                          width: calc(100% - 50px);
                          p {
                            margin-bottom: 0;
                            color: $crousal_heading;
                            font-size: 13px;
                            transition: all 0.5s ease-in-out;
                          }
                          span {
                            color: $location;
                            font-size: 13px;
                            transition: all 0.5s ease-in-out;
                          }
                          // &:not(:last-child) {
                          //   border-bottom: 1px solid #d9d9d9;
                          // }
                        }
                      }
                    }
                    a {
                      &:hover {
                        .wrap_noti_card {
                          // background-color: $footer_background;
                          background-color: #3fc1c930;
                          border-radius: 8px;
                        }
                      }
                    }
                    .wrap_noti {
                      p {
                        margin-bottom: 0;
                        text-align: center;
                        font-size: 14px;
                        cursor: pointer;
                      }
                    }
                    .fix_center_noti {
                      height: 100%;
                      display: flex;
                      align-items: center;
                      .wrapper_blank_noti {
                        text-align: center;
                        .cstm_noti_miss {
                          max-width: 160px;
                          width: 100%;
                          margin: 0 auto;
                          margin-bottom: 20px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.mobile_menu {
  &.offcanvas {
    .offcanvas-body {
      .navbar-nav {
        .nav-link {
          font-weight: 400;
          font-size: 14px;
          color: $nav-text-color;
          margin-right: 25px;
        }
      }
      .wrapper_nav_btn {
        .nav_btn {
          padding: 5px 10px;
          border: 1px solid $btn_border-1;
          background-color: unset;
          color: $nav-text-color;
          font-weight: 600;
          font-size: 14px;
        }
        .nav_btn_cstm {
          padding: 5px 15px;
          background-color: $btn_border-common;
          border: 1px solid $btn_border-common;
          color: $white-color;
          font-weight: 600;
          font-size: 14px;
        }
      }
    }
  }
}
.mobile_user_view {
  &.offcanvas {
    .offcanvas-body {
      .navbar-nav {
        .nav-link {
          svg {
            font-size: 22px;
            margin-right: 5px;
          }
        }
      }
      .dropdown {
        max-width: 180px;
        width: 100%;
        justify-content: space-between;
        display: flex;
        a {
          display: flex;
          align-items: center;
          justify-content: start;
          color: $nav-text-color;
          width: 100%;
          @include break-point(miniTabs) {
            margin-bottom: 10px;
            margin-top: 10px;
          }
          svg {
            font-size: 30px;
          }
          figure {
            margin-bottom: 0;
            width: 35px;
            height: 35px;
            border-radius: 50%;
            overflow: hidden;
            margin-right: 10px;
            img {
              height: 100%;
              object-fit: cover;
            }
          }
          span {
            font-size: 14px;
          }
        }
        .cstm_drop_anchor {
          flex-direction: row-reverse;
          .drop_fig {
            display: flex;
            align-items: center;
          }
        }
        &.show {
          a {
            & svg {
              transform: rotate(180deg);
            }
          }
        }
        .dropdown-menu {
          padding: 10px 0px 0px 0px;
          overflow: hidden;
          .dropdown-item {
            justify-content: start;
            padding-bottom: 10px;
            padding-top: 5px;
            .wrap_fig_drop {
              display: flex;
              align-items: center;
              .wrap_user_drop {
                p {
                  margin-bottom: 0;
                }
                span {
                  font-size: 12px;
                }
              }
              p {
                margin-bottom: 0;
                font-size: 12px;
              }
              svg {
                position: unset;
                transform: unset;
                font-size: 18px;
                margin-right: 10px;
              }
            }
            &:active {
              background-color: #fff;
            }
          }
          .cstm_Drop {
            padding-bottom: 10px;
            border-bottom: 1px solid #d9d9d9;
            .wrap_fig_drop {
              p {
                margin-bottom: 0;
                font-size: unset;
                font-size: 14px;
              }
            }
          }
          .log_out {
            padding: 10px 0px;
            border-top: 1px solid #d9d9d9;
            text-align: center;
            justify-content: center;
          }
        }
        &.cstm_dropdown {
          max-width: 130px;
          width: 100%;
          margin-right: 20px;
          a {
            svg {
              display: none;
            }
            .cstm_bell {
              position: unset;
              display: block;
              font-size: 20px;
              margin-right: 5px;
              transform: unset;
            }
          }
          .dropdown-menu {
            min-width: 320px;
            width: 100%;
            padding-top: 0;
            .cstm_Drop {
              padding: 0;
              .wrap_fig_drop {
                display: flex;
                justify-content: space-between;
                width: 100%;
                align-items: center;
                padding: 10px 15px;
                h6 {
                  margin-bottom: 0;
                  color: $crousal_heading;
                  font-weight: 600;
                  font-size: 22px;
                }
                span {
                  color: $btn_border-common;
                  font-size: 12px;
                  cursor: pointer;
                }
              }
            }
            .today_notification {
              padding: 10px 15px;
              height: 50vh;
              overflow-y: scroll;
              .heading_p {
                margin-bottom: 10px;
                color: $location;
                font-size: 14px;
              }
              .wrap_noti_card {
                display: flex;
                margin-bottom: 15px;
                padding: 3px 5px;
                transition: all 0.5s ease-in-out;
                figure {
                  width: 70px;
                  height: 40px;
                  overflow: hidden;
                  border-radius: 50%;
                  background-color: lightgray;
                  margin-right: 7px;
                  img {
                    object-fit: none;
                  }
                }
                .noti_with_time {
                  border-bottom: 1px solid #d9d9d9;
                  p {
                    margin-bottom: 0;
                    color: $crousal_heading;
                    font-size: 13px;
                  }
                  span {
                    color: $location;
                    font-size: 13px;
                  }
                }
              }
              .wrap_noti {
                p {
                  margin-bottom: 0;
                  text-align: center;
                  font-size: 14px;
                  cursor: pointer;
                }
              }
            }
          }
        }
      }
    }
  }
}
