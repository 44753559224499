@import "../../scss/index.scss";

.wrapper_filter {
  .wrap_filter {
    .row {
      .cstm_hide_show {
        position: sticky;
        top: 0px;
        height: 100vh;
        overflow-y: scroll;
        transition: all 0.4s linear;
        @include scroll(y);
        @include break-point(tablet) {
          // position: unset;
          // height: unset;
          // overflow: unset;
          left: -100%;
          position: absolute;
        }

        .wrap_left_filter {
          .wrap_select {
            position: relative;
            svg {
              position: absolute;
              top: 48%;
              left: 10px;
              transform: translateY(-52%);
              color: $form_heading;
            }
          }
          .wrapper_clear {
            background-color: $white-color;
            padding: 15px;
            .filters_cstm_close {
              text-align: end;
              padding-bottom: 20px;
              display: none;

              @include break-point(tablet) {
                display: block;
              }
              span {
                font-size: 14px;
                border: 1px solid lightgray;
                border-radius: 50%;
                padding: 3px;
                display: inline-flex;
              }
            }
            .filters {
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding-bottom: 20px;

              span {
                color: $btn_border-common;
                font-size: 14px;
                cursor: pointer;
              }
            }
            .range_slider {
              border-top: 1px solid #f2f2f2;
              padding-top: 20px;
              .horizontal-slider {
                padding-top: 20px;
                .example-track {
                  &.example-track-0 {
                    height: 5px;
                    background-color: #edeeee;
                  }
                  &.example-track-1 {
                    height: 5px;
                    background-color: #40c2c9;
                  }
                  &.example-track-2 {
                    height: 5px;
                    background-color: #edeeee;
                  }
                }
                .example-thumb {
                  &.example-thumb-0 {
                    padding: 0px 5px;
                    border-radius: unset;
                    background-color: #40c2c9;
                    border: 1px solid $white-color;
                    color: $white-color;
                    top: 50%;
                    cursor: pointer;
                    &:focus {
                      border-color: $white-color;
                      outline: unset;
                    }
                  }
                  &.example-thumb-1 {
                    padding: 0px 5px;
                    border-radius: unset;
                    background-color: #40c2c9;
                    border: 1px solid $white-color;
                    color: $white-color;
                    top: 50%;
                    cursor: pointer;
                    &:focus {
                      border-color: $white-color;
                      outline: unset;
                    }
                  }
                }
              }
              .wrap_cstm_input {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 30px 0px;

                .left_input {
                  max-width: 40%;
                  width: 100%;
                  .cstm_filter_input {
                    padding: 10px 5px 10px 5px;
                  }
                }
                .right_input {
                  max-width: 40%;
                  width: 100%;
                  .cstm_filter_input {
                    padding: 10px 5px 10px 5px;
                  }
                }
              }
              .radio_wrap {
                padding: 10px 5px;
                border: 1px solid #b1c1d4;
                display: flex;
                justify-content: space-between;
                align-items: center;
                & > div {
                  display: flex;
                  align-items: center;
                }
                svg {
                  color: #5ec83a;
                  margin-right: 10px;
                }
                .form-switch {
                  .form-check-input {
                    border-color: #dee2e6;
                    cursor: pointer;
                  }
                  .form-check-input[type="checkbox"] {
                    border-radius: 2em;
                  }
                }
              }
              .property_type {
                padding: 20px 0px 20px 0px;
                border-bottom: 1px solid #f2f2f2;

                .wrap_type {
                  padding-top: 20px;
                  form {
                    .wrap_checkbox {
                      display: flex;
                      align-items: center;
                      justify-content: space-between;
                      flex-wrap: wrap;
                      .form-check {
                        max-width: 50%;
                        width: 100%;
                        .form-check-input {
                          cursor: pointer;
                        }
                        .form-check-label {
                          font-size: 14px;
                          font-weight: 400;
                          // cursor: pointer;
                        }
                      }
                    }
                  }
                }
              }
              .wrap_filter_accordian {
                .accordion {
                  .accordion-item {
                    border-bottom: 1px solid #f2f2f2;
                    .accordion-header {
                      padding: 15px 0px;
                      p {
                        margin-bottom: 0;
                        letter-spacing: 0.5px;
                        color: $form_heading;
                      }
                      .accordion-button {
                        background-color: $white-color;
                        box-shadow: unset;
                        padding: 0px 0px;
                        &:focus {
                          border: unset;
                          box-shadow: unset;
                        }
                      }
                    }
                    .accordion-collapse {
                      .accordion-body {
                        padding: 0px 0px 15px 0px;
                        .wrap_span_select {
                          display: flex;
                          align-items: center;
                          flex-wrap: wrap;
                          &.over_write_span_select {
                            span {
                              padding: 5px;
                              width: unset;
                              max-width: unset;
                            }
                          }
                        }
                        .property_type {
                          padding: 0;
                          border: unset;
                          .wrap_type {
                            padding: 0;
                            .wrap_checkbox {
                              .datepicker {
                                width: 100%;
                                padding-top: 15px;
                                label {
                                  font-size: 13px;
                                  color: $form_heading;
                                }
                              }
                              &.over_write_checkbox {
                                .form-check {
                                  max-width: 100%;
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      .wrap_right_filter {
        .cstm_searchbar {
          margin: 0 auto;
          width: 100%;
          display: flex;
          background-color: #ffffff;
          align-items: center;
          padding: 2px;
          border: 1px solid $filter_input;

          .cstm_input {
            padding: 7px 30px 7px 20px;
            &:focus {
              border: 1px solid $white-color;
            }
          }
          .nav_btn_cstms {
            min-height: 37px;
            svg {
              margin-right: 5px;
            }
          }
        }
        .wrapper_filter_btn {
          display: flex;
          align-items: center;
          .cstm_searchbar {
            @include break-point(tablet) {
              max-width: 90%;
              margin: unset;
            }
            @include break-point(mobile) {
              max-width: 80%;
            }
          }
          .mobile_filter {
            display: none;
            @include break-point(tablet) {
              display: inline-flex;
              max-width: calc(100% - 90%);
              width: 100%;
              display: flex;
              justify-content: right;
            }
            @include break-point(mobile) {
              max-width: calc(100% - 80%);
            }
            .nav_btn_cstms_overwrite {
              min-height: 43px;
              max-width: 50px;
              background-color: $white-color;
              border: 1px solid $filter_input;
              cursor: pointer;
              img {
                width: unset;
              }
            }
          }
        }
        .filter_card_details {
          .wrap_filter_select_cards {
            span {
              border: 1px solid $filter_input;
              background-color: $white-color;
              text-align: center;
              margin-right: 5px;
              padding: 5px 10px;
              margin-bottom: 5px;
              svg {
                font-size: 10px;
                cursor: pointer;
                margin-left: 5px;
                margin-bottom: 2px;
              }
            }
          }
          .wrap_cards_filters_property {
            padding-top: 25px;
            .row {
              .wrap_listing_card {
                margin-bottom: 20px;
              }
            }
          }
        }
      }
    }
  }
}

.innerside_filter {
  overflow: hidden;
  .wrapper_filter {
    .wrap_filter {
      .row {
        .cstm_hide_show {
          left: 0;
          max-width: 40%;
          width: 100%;
          z-index: 9;
          padding-left: 0;
          padding-right: 0;
          @include break-point(mobile) {
            max-width: 80%;
          }
        }
      }
    }
  }
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #0000005e;
    top: 0;
  }
}
