@import "../../scss/index.scss";
.wrap_image_property {
  background-color: $white-color;
  padding: 15px;
  .row {
    &.margin_top {
      @include break-point(miniTabs) {
        margin-top: 10px;
      }
    }
    .wrap_property_section {
      .large_property {
        height: 307px;
        width: 100%;
        margin-bottom: 0;
        img {
          height: 100%;
          object-fit: fill;
        }
      }
    }
    .padding_right_add {
      @include break-point(miniTabs) {
        padding-right: unset;
      }
      @include break-point(mobile) {
        padding-right: calc(var(--bs-gutter-x) * 0.5);
      }
    }
    .padding-right_0 {
      @include break-point(miniTabs) {
      }
      @include break-point(mobile) {
        padding-right: calc(var(--bs-gutter-x) * 0.5);
      }
    }
    .wrap_property_section_half {
      .large_property {
        position: relative;
        width: 100%;
        height: 145px;
        cursor: pointer;
        img {
          height: 100%;
          object-fit: fill;
        }
        .tag_span {
          border: 1px solid rgba(239, 239, 239, 0.44);
          // background-color: rgba(239, 239, 239, 0.44);
          padding: 5px;
          color: $white-color;
          position: absolute;
          bottom: 6px;
          right: 10px;
          cursor: pointer;
          font-size: 14px;
          font-weight: 600;
        }
      }
    }
  }
  .propert_detailing_list {
    .row {
      .wrap_inner_card {
        .cstm_flex {
          display: flex;
          justify-content: space-between;
          .location {
            h5 {
              font-weight: 600;
              color: $form_heading;
              font-size: 22px;
              span {
                background-color: #f4ae23;
                font-weight: 700;
                color: $white-color;
                padding: 3px 5px;
                display: inline-flex;
                align-items: center;
                margin-left: 20px;
                svg {
                  font-size: 11px;
                  margin-right: 5px;
                  margin-bottom: 1px;
                }
              }
            }
            .location_tag {
              color: $form_heading;
              font-size: 14px;
              svg {
                margin-bottom: 3px;
                margin-right: 5px;
              }
            }
          }
          .amount {
            h5 {
              color: $form_heading;
              text-align: end;
              span {
                font-size: 14px;
                font-weight: 600;
                color: $form_heading;
              }
            }
          }
        }
        .cstm_ul {
          border-top: 1px solid #d9d9d9;
          border-bottom: 1px solid #d9d9d9;
          padding: 12px 0px;
          margin-top: 15px;
          display: flex;
          justify-content: space-between;
          @include break-point(mobile) {
            align-items: center;
          }
          ul {
            display: flex;
            align-items: center;
            @include break-point(mobile) {
              display: block;
            }
            li {
              padding-right: 20px;
              color: $form_heading;
              .svg_dots {
                display: none;
                @include break-point(mobile) {
                  display: inline-block;
                }
              }
              svg {
                font-size: 12px;
                margin-right: 10px;
                color: #c9c9c9;
              }
            }
          }
          .cstm_btn {
            border: 1px solid $profile_input_color;
            padding: 5px 10px;
            cursor: pointer;
            font-size: 14px;
            color: $form_heading;

            svg {
              font-size: 14px;
              margin-right: 10px;
              margin-bottom: 3px;
            }
          }
        }
        .about_property {
          padding: 20px 0px;
          h5 {
            font-size: 22px;
            font-weight: 600;
            color: $form_heading;
          }
          .clr {
            color: $crousal_para;
            a {
              text-decoration: underline;
              color: $crousal_para;
            }
          }
        }
        .wrap_overview {
          padding: 30px 0px;
          border-top: 1px solid $nav_border-color;
          h6 {
            font-weight: 600;
            font-size: 20px;
            margin-bottom: 15px;
          }
          .row {
            .wrap_over_card {
              p {
                font-weight: 600;
                margin-bottom: 0;
                font-size: 18px;
              }
              span {
                color: $crousal_para;
                font-size: 16px;
              }
            }
          }
        }
        .wrap_facilities {
          .row {
            .wrap_over_card {
              .wrap_img_txt {
                display: flex;
                align-items: center;
                figure {
                  // width: 24px;
                  margin-bottom: 0;
                  margin-right: 10px;
                }
                span {
                  color: $form_heading;
                  font-size: 16px;
                }
              }
            }
          }
        }
        .wrap_ratings {
          .wrap_div {
            display: flex;
            align-items: center;
            justify-content: space-between;
            h6 {
              margin-bottom: 5px;
            }
            .location_tag {
              font-size: 14px;
              svg {
                margin-right: 10px;
                margin-bottom: 6px;
              }
            }
          }
          .row {
            margin-top: 15px;
            .wrap_review {
              border: 1px solid #e3e6e9;
              padding: 12px;
              margin-bottom: 20px;
              span {
                font-size: 14px;
                color: $crousal_para;
              }
              .wrap_review_user {
                display: flex;
                align-items: center;
                padding: 10px 0px;
                figure {
                  width: 40px;
                  height: 40px;
                  overflow: hidden;
                  border-radius: 50%;
                  margin-bottom: 0;
                  img {
                    height: 100%;
                    object-fit: fill;
                  }
                }
                .wrap_review_star {
                  padding-left: 20px;
                  p {
                    font-weight: 600;
                    margin-bottom: 0px;
                  }
                  .wrap_star_dots {
                    display: flex;
                    align-items: center;
                    .star-ratings {
                      .star-container {
                        padding-right: 0px !important ;
                        svg {
                          width: 20px !important;
                          height: 20px !important;
                        }
                      }
                    }
                    span {
                      padding-left: 10px;
                      color: #768aa1;
                      font-size: 12px;
                      svg {
                        font-size: 8px;
                        color: #768aa1;
                      }
                    }
                  }
                }
              }
            }
            .btn_see_wrapper {
              margin-top: 15px;
              .btn_see_more {
                max-width: 170px;
              }
            }
          }
        }
        .wrap_Things {
          .row {
            .wrap_things {
              p {
                font-weight: 600;
                margin-bottom: 10px;
                font-size: 18px;
              }
              span {
                font-size: 16px;
                margin-bottom: 20px;
                color: $crousal_para;
              }
              ul {
                list-style: disc;
                padding-left: 18px;
                li {
                  color: $crousal_para;
                }
              }
            }

            .top_space {
              @include break-point(mobile) {
                margin-top: 20px;
              }
            }
          }
        }
      }
      .inner_chat_card {
        padding-left: 0px 0px 0px 10px;
        .card_chat {
          background-color: $btn_border-common;
          padding: 15px;
          margin-bottom: 15px;
          .wrap_chat_img {
            display: flex;
            align-items: center;
            figure {
              width: 60px;
              height: 60px;
              overflow: hidden;
              border-radius: 50%;
              margin-bottom: 0;
              img {
                height: 100%;
                object-fit: fill;
              }
            }
            .hosted {
              padding-left: 20px;
              p {
                color: $white-color;
                margin-bottom: 0;
              }
              h5 {
                color: $white-color;
                svg {
                  margin-left: 10px;
                  margin-bottom: 3px;
                }
              }
            }
          }
          .wrap_chat_btn {
            padding-top: 20px;
            .chat_now_btn {
              width: 100%;
              min-height: 40px;
              background-color: #ffffff;
              border: 1px solid #ffffff;
              font-weight: 400;
              font-size: 16px;
              display: flex;
              align-items: center;
              justify-content: center;
              color: $btn_border-common;
              svg {
                margin-right: 10px;
                transform: rotateY(180deg);
              }
            }
          }
        }
        .lease {
          padding: 15px;
          border: 1px solid $profile_input_color;

          p {
            margin-bottom: 5px;
            font-weight: 600;
          }
          .wrap_label {
            padding-bottom: 20px;
            label {
              display: inline-block;
              padding: 0px;
              margin-right: 10px;
              cursor: pointer;
              max-width: 30%;
              width: 100%;
              padding-bottom: 15px;

              @include break-point(lscapeTablet) {
                max-width: 40%;
              }

              input {
                visibility: hidden;
                width: 0;
                height: 0;

                &:checked {
                  & + span {
                    &::after {
                      opacity: 1;
                    }
                  }
                }
              }
              span {
                position: relative;
                line-height: 16px;
                &::before {
                  content: "";
                  border: 1px solid $form_heading;
                  width: 16px;
                  height: 16px;
                  margin-right: 10px;
                  display: inline-block;
                  vertical-align: top;
                }
                &::after {
                  content: "";
                  background: $form_heading;
                  width: 8px;
                  height: 8px;
                  position: absolute;
                  top: 4px;
                  left: 4px;
                  transition: 300ms;
                  opacity: 0;
                }
              }
            }
          }
        }
        .btn_book_now {
          margin-top: 20px;
          .btn_see_more {
            background-color: $btn_border-common;
            border: 1px solid $btn_border-common;
            color: $white-color;
            max-width: 100%;
            width: 100%;
            font-weight: 600;
          }
        }
      }
    }
  }
}
