@import "../../scss/index.scss";

.wrapper_find_and_choose {
  background-image: url(../../assets/images/landing_bg.png);
  background-repeat: no-repeat;
  background-position: 100%;
  background-size: 100% 100%;
  padding: 30px 0px;
  position: relative;
  .wrapper_txt_image {
    position: relative;
    z-index: 1;
    .wrapper_txt {
      text-align: center;
      h1 {
        font-weight: 700;
        font-size: 52px;
        color: $white-color;
        @include break-point(mobilestart) {
          font-size: 36px;
        }

        .font_light {
          font-weight: 400;
          font-size: 52px;
          @include break-point(mobilestart) {
            font-size: 36px;
          }
        }

        .font_heavy {
          font-weight: 700;
          font-size: 52px;
          @include break-point(mobilestart) {
            font-size: 36px;
          }
        }
      }
      .heading_para {
        color: $white-color;
        font-size: 18px;
      }
      .wrap_stats {
        display: flex;
        justify-content: space-between;

        .wrap_stats_card {
          p {
            font-weight: 600;
            color: $btn_border-common;
            margin-bottom: 0;
            font-size: 32px;
            line-height: 1;
          }
          span {
            color: $black-color;
          }
        }
      }
      .wrap_play_store {
        padding: 20px 0px;
        a {
          max-width: 145px;
          width: 100%;
          img {
            width: 100%;
          }
        }
      }
    }
    .wrapper_search {
      margin-top: 50px;
      .cstm_searchbar {
        margin: 0 auto;
        width: 60%;
        display: flex;
        margin-bottom: -50px;
        background-color: $white-color;
        align-items: center;
        padding: 5px;
        // box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.2);
        box-shadow: 0px 3px 8px rgb(100, 100, 100);
        @include break-point(mobilestart) {
          width: 90%;
        }
        .wrap_location {
          width: 50%;
          padding-bottom: 5px;
          border-right: 1px solid rgba(217, 217, 217, 1);
          label {
            font-size: 13px;
            padding-left: 10px;
          }
          .cstm_input {
            padding: 0px 0px 0px 10px;
            color: $location;
          }
        }
        .nav_btn_cstms {
          svg {
            margin-right: 5px;
          }
        }
      }
    }
  }
  &::after {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.28);
  }
}
.wrap_about_us {
  padding: 100px 0px 40px 0px;
  @include break-point(lscapeTablet) {
    padding: 40px 0px 20px 0px;
  }
  .wrap_about_section {
    .row {
      .wrap_card {
        position: relative;
        @include break-point(miniTabs) {
          max-width: 450px;
          margin: 0 auto;
        }
        @include break-point(cstmmobile) {
          max-width: unset;
          margin: unset;
        }
        .wrap_fig_img {
          max-width: 350px;
          width: 100%;
          margin-bottom: 0;
          @include break-point(xllgdesktop) {
            max-width: 450px;
          }
          @include break-point(desktop) {
            max-width: 350px;
          }
          @include break-point(cstmmobile) {
            max-width: 290px;
          }
        }
        .wrap_inner_img {
          position: absolute;
          bottom: 20px;
          right: 30px;
          max-width: 200px;
          width: 100%;
          margin-bottom: 0;
          @include break-point(xllgdesktop) {
            max-width: 230px;
            right: 20px;
          }
          @include break-point(desktop) {
            max-width: 200px;
            right: 30px;
          }
          @include break-point(lscapeTablet) {
            right: 0px;
          }
          @include break-point(cstmmobile) {
            max-width: 160px;
            right: 30px;
          }
        }
        .wrap_arrow_img {
          max-width: 160px;
          width: 100%;
          position: absolute;
          right: -37px;
          top: 10px;
          margin-bottom: 0;
          z-index: -1;
          @include break-point(cstmmobile) {
            right: 0px;
          }
        }
      }
      .wrap_card-txt {
        padding: 40px 20px 20px 50px;
        @include break-point(smalltablet) {
          padding: 40px 20px 20px 20px;
        }

        h6 {
          color: $btn_border-common;
          padding-left: 70px;
          font-size: 20px;

          @include break-point(xllgdesktop) {
            padding-left: 90px;
          }
          @include break-point(desktop) {
            padding-left: 70px;
          }

          position: relative;
          &::before {
            content: "";
            position: absolute;
            width: 10%;
            height: 1px;
            left: 0;
            top: 10px;
            background-color: $btn_border-common;
          }
        }
        h3 {
          color: $form_heading;
        }
        p {
          color: $form_heading;
          padding-top: 15px;
        }

        span {
          color: $form_heading;
          font-size: 16px;
          width: calc(100% - 40px);
        }

        ul {
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-wrap: wrap;
          li {
            display: flex;
            width: 50%;
            align-items: center;
            padding-bottom: 15px;
            color: $form_heading;
            @include break-point(cstmmobile) {
              width: 100%;
            }

            figure {
              width: 40px;
              height: 40px;
              background: $btn_border-common;
              border-radius: 50%;
              display: flex;
              justify-content: center;
              margin-bottom: 0;
              margin-right: 10px;
              align-items: center;
              img {
                width: 60%;
                height: 55%;
              }
            }
          }
        }
      }
    }
  }
}
.wrap_crousal_landing {
  padding: 30px 0px;
  @include break-point(cstmmobile) {
    padding: 10px 0px;
  }
  .parent_wrap_crousal {
    h5 {
      color: $btn_border-1;
    }
    p {
      color: $crousal_para;
    }
  }
  .slick-slider {
    .custom-arrow {
      &.next {
        position: absolute;
        top: -50px;
        right: 10px;
        left: unset;
        width: 40px;
        height: 40px;
        border: 1px solid $crousal_btn_border;
        background: transparent;
        transition: all 0.5s ease-in-out;
        display: flex;
        align-items: center;
        justify-content: center;
        &::before {
          font-size: 20px;
          line-height: 1;
          opacity: 0.75;
          color: #000;
        }
        &:hover {
          background-color: $footer_background;
          svg {
            color: $white-color;
          }
        }
      }
      &.prev {
        position: absolute;
        top: -50px;
        right: 60px;
        left: unset;
        width: 40px;
        height: 40px;
        border: 1px solid $crousal_btn_border;
        background: transparent;
        transition: all 0.5s ease-in-out;
        display: flex;
        align-items: center;
        justify-content: center;

        &::before {
          font-size: 20px;
          line-height: 1;
          opacity: 0.75;
          color: #000;
        }
        &:hover {
          background-color: $footer_background;
          svg {
            color: $white-color;
          }
        }
      }
    }
    .slick-list {
      .slick-track {
        .slick-slide {
          & > div {
            padding: 0px 10px 20px 0px;

            @include break-point(cstmmobile) {
              padding: 0px 0px 20px 0px;
            }
          }
        }
      }
    }
  }
}
.wrap_Client_saying {
  padding-bottom: 180px;
  .wrap_client_section {
    position: relative;
    h6 {
      color: $btn_border-common;
      padding-left: 70px;
      font-size: 20px;

      @include break-point(xllgdesktop) {
        padding-left: 90px;
      }
      @include break-point(desktop) {
        padding-left: 70px;
      }

      position: relative;
      &::before {
        content: "";
        position: absolute;
        width: 5%;
        height: 1px;
        left: 0;
        bottom: 10px;
        background-color: $btn_border-common;
        @include break-point(mobilestart) {
          width: 10%;
        }
      }
    }
    h2 {
      color: $crousal_heading;
    }
    .wrapadd_padding {
      padding-top: 40px;
      @include break-point(cstmmobile) {
        padding-bottom: 20px;
      }
      .wrap-testimonial {
        background: $background_test_card;
        max-width: 550px;
        width: 100%;
        position: relative;
        padding: 30px 30px 20px 30px;
        .coats_wrap_img {
          width: 50px;
          position: absolute;
          right: 0;
          top: -17px;
        }
        ul {
          display: flex;
          padding-bottom: 20px;
          li {
            padding-right: 5px;
            svg {
              color: $fa_star_rating;
              font-size: 18px;
            }
          }
        }
        p {
          font-weight: 400;
        }
        .wrap_dealer_name {
          display: flex;
          align-items: center;
          padding-top: 5px;
          padding-bottom: 2px;
          figure {
            border-radius: 50%;
            height: 40px;
            width: 40px;
            overflow: hidden;
            margin-bottom: 0;
            img {
              width: 100%;
              height: 100%;
              object-fit: fill;
            }
          }
          .name_wrap {
            padding-left: 10px;
            p {
              color: $form_heading;
              margin-bottom: 0;
              font-weight: 600;
            }
            span {
              color: $form_heading;
            }
          }
        }
      }
    }
    .subscribe_footer {
      background-color: $btn_border-common;
      padding: 40px 80px;
      position: absolute;
      bottom: -205px;
      width: 100%;

      @include break-point(landscapeTab) {
        padding: 20px 80px;
      }
      @include break-point(mobilestart) {
        padding: 20px 80px 20px 40px;
      }

      .row {
        align-items: center;
        .wrap_col_subs {
          h4 {
            color: $white-color;
          }
          p {
            color: $white-color;
          }
        }
        .wrap_input_btn {
          display: flex;
        }
      }
      .cstm_subscribe_right {
        position: absolute;
        right: 0;
        bottom: 0;
        max-width: 50px;
      }
    }
  }
}
