//----------------------------$text-color--------------------------------------//
$white-color: #ffffff;
$black-color: #000000;
$nav-text-color: #26405d;
$btn_border-1: #26405e;
$btn_border-common: #3fc1c9;
$txt_heading: #274864;
$footer_background: #0e2a3e;
$crousal_heading: #0e2a3e;
$crousal_para: #59718b;
$form_heading: #27415e;
$location: #888888;
$crousal_btn_border: #a2b4c8;
$background_test_card: #0e2a3e0a;
$fa_star_rating: #fdb01a;
$nav_border-color: #d9d9d9;
$profile_input_color: #e9e9e9;
$filter_input: #b1c1d4;
$btn_cancel: #F85A5A;
