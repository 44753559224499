@import "../../../scss/index.scss";

.wrap_about_us_wrap {
  .form_profile_set {
    .about_us_profile {
      width: 110px;
    }
    .cstm_P_about_us {
      font-size: 14px;
      color: $form_heading;
      line-height: 1.7;
    }
    .about_us_img{
        max-width: 1080px;
        width: 100%;
    }
  }
}
