@import "../../scss/index.scss";

.wrap_auth {
  @include break-point(mobile) {
    height: 100vh;
  }
  .wrap_crousel_form {
    display: flex;
    @include break-point(miniTab) {
      flex-direction: column-reverse;
    }
    @include break-point(mobile) {
      height: 100vh;
    }
    .wrap_crousel {
      width: 60%;
      @include break-point(smalltablet) {
        width: 50%;
      }
      @include break-point(miniTab) {
        width: 100%;
      }
      @include break-point(mobile) {
        display: none;
      }
      .slick-slider {
        height: 100%;
        .slick-dots {
          bottom: 45px;
          li {
            width: 0px;
            height: 0px;
            margin: 0 12px;
            button {
              &::before {
                color: $btn_border-common;
                opacity: 0.8;
                border: 1px solid $btn_border-common;
                content: "";
                border-radius: 50%;
                width: 10px;
                height: 10px;
              }
            }
          }
          .slick-active {
            button {
              &::before {
                color: $btn_border-common;
                border: 1px solid $btn_border-common;
                background-color: $btn_border-common;
                content: "";
                border-radius: 50%;
                width: 10px;
                height: 10px;
              }
            }
          }
        }
        .slick-list {
          height: 100%;
          .slick-track {
            height: 100%;
            .slick-slide {
              height: 100%;
              & > div {
                height: 100%;
              }
              .item {
                position: relative;
                display: block !important;
                height: 100%;
                figure {
                  margin: 0;
                  height: 100%;
                  img {
                    height: 100%;
                  }
                }
                .wrap_card_txt {
                  background-color: $white-color;
                  padding: 10px 30px;
                  width: 85%;
                  left: 0;
                  right: 0;
                  margin: 0 auto;
                  position: absolute;
                  bottom: 85px;

                  h5 {
                    color: $crousal_heading;
                  }
                  p {
                    color: $crousal_para;
                  }
                }
              }
            }
          }
        }
        .slick-arrow {
          display: none !important;
        }
      }
    }
    .wrap_login_register_form {
      width: calc(100% - 60%);
      background-color: rgba(63, 193, 201, 0.07);
      @include break-point(mobile) {
        height: 100%;
      }

      @include break-point(smalltablet) {
        width: calc(100% - 50%);
      }
      @include break-point(miniTab) {
        width: 100%;
      }

      .wrap_form_login {
        padding: 20px 55px 20px 30px;
        @include break-point(cstmmobile) {
          padding: 20px 15px 20px 15px;
        }
        h3 {
          color: $form_heading;
        }
        span {
          color: $nav-text-color;
        }
        .cstm_font_increase{
          font-size: 18px;
          @include break-point(mobile){
            font-size: 16px;

          }
        }
        .wrap_tabs_pills {
          margin-top: 20px;
          // .tab-content {
          form {
            .wrap_form_fields {
              margin-top: 20px;
              margin-bottom: 15px;
              position: relative;
              label {
                font-size: 15px;
                color: $nav-text-color;
                margin-bottom: 8px;
                font-weight: 600;
              }
              span {
                a {
                  text-decoration: underline;
                }
              }
              a {
                color: $form_heading;
                font-size: 13px;
                font-weight: 600;
              }
              &.wrap_form_flex {
                display: flex;
                align-items: center;
                justify-content: space-between;
                .wrap_select {
                  width: 47%;
                  .form-select {
                    font-size: 14px;
                    border-radius: unset;
                    border: 1px solid $white-color;
                    &:focus {
                      outline: unset;
                      border: 1px solid $btn_border-common;
                      box-shadow: unset;
                    }
                  }
                }
              }
              &.wrap_check_flex {
                display: flex;
                .form-check-input {
                  margin-right: 5px;
                  margin-top: 0px;
                }
              }
              .cstm_icons_set {
                position: absolute;
                bottom: 10px;
                right: 20px;
                cursor: pointer;
              }
              &.cstm_error {
                span {
                  bottom: 34px;
                }
              }
            }
          }
          // }
          .nav-tabs {
            .nav-item {
              max-width: 150px;
              width: 100%;
              padding-bottom: 20px;
              position: relative;
              @include break-point(cstmmobile) {
                max-width: 100px;
              }
              .nav-link {
                margin-bottom: unset;
                border: 0;
                color: $nav-text-color;
                padding: unset;
                &::after {
                  content: "";
                  height: 2px;
                  width: 0px;
                  position: absolute;
                  background: $btn_border-common;
                  top: 43px;
                  left: 0;
                  transition: 0.5s ease-in-out;
                  z-index: 0;
                }
                &.active {
                  background-color: unset;
                  &::after {
                    width: 100%;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
