@import "../../scss/index.scss";

.wrap_chat_message {
  background-color: $white-color;
  display: flex;
  .wrapside_chat {
    width: 100%;
    max-width: 300px;
    height: 80vh;
    overflow: hidden;
    border-right: 1px solid $nav_border-color;
    @include break-point(miniTabs) {
      max-width: 0px;
      width: 0;
    }
    .wrapper_accont_setting {
      &::-webkit-scrollbar {
        width: 0em;
        height: 0em;
      }
      .head_setting {
        padding: 15px;
        border-bottom: 1px solid $nav_border-color;
        border-right: 1px solid $nav_border-color;
        min-height: 53px;
        h6 {
          margin-bottom: 0;
          font-size: 18px;
          color: $form_heading;
          display: flex;
          align-items: center;
          .cstm_left_arrow {
            margin-right: 10px;
            cursor: pointer;
          }
        }
      }
      .wrap_nav {
        .search_chat {
          position: relative;
          svg {
            position: absolute;
            top: 13px;
            left: 15px;
          }
          .chat_input {
            border-color: $nav_border-color;
            border-top: unset;
            border-left: unset;
            padding: 10px 20px 10px 40px;
            &::placeholder {
              color: #8092a7;
            }
          }
        }
        .wrap_archived {
          border-bottom: 1px solid $nav_border-color;
          border-right: 1px solid $nav_border-color;
          padding: 15px;
          a {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            color: $form_heading;
            font-size: 14px;
            .wrap_img {
              display: flex;
              align-items: center;
              figure {
                width: 20px;
                margin-bottom: 0;
                margin-right: 20px;
              }
              span {
                font-size: 14px;
              }
            }
          }
        }
        ul {
          position: relative;
          height: calc(80vh - 150px);
          overflow: auto;
          @include scroll(y);
          li {
            .cstm_active_wrap {
              width: 100%;
              padding-left: 5px;
              color: $form_heading;
              position: relative;
              cursor: pointer;
              svg {
                font-size: 22px;
                width: 54px;
                transition: all 0.2s ease-in-out;
              }
              span {
                font-size: 14px;
                transition: all 0.2s ease-in-out;
              }
              &:hover {
                &::before {
                  opacity: 1;
                }
              }
              &.active {
                .wrap_img {
                  border-right: 0;
                }
                &::before {
                  opacity: 1;
                }
              }
              &::before {
                content: "";
                position: absolute;
                width: 3px;
                height: 100%;
                left: 0;
                background-color: $btn_border-common;
                opacity: 0;
                transition: all 0.2s ease-in-out;
              }
              .wrap_img {
                display: flex;
                align-items: center;
                padding: 0px 0px 0px 10px;
                justify-content: space-between;
                border-right: 1px solid $nav_border-color;
                figure {
                  width: 40px;
                  height: 40px;
                  margin-bottom: 0;
                  img {
                    height: 100%;
                    object-fit: cover;
                  }
                }
                .wrap_today {
                  width: calc(100% - 45px);
                  padding-left: 10px;
                  .flex_tag_span {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding-top: 10px;
                    padding-right: 5px;

                    p {
                      margin-bottom: 0;
                      font-size: 14px;
                      font-weight: 600;
                    }
                    span {
                      font-size: 10px;
                      padding: 0;
                      border-bottom: 0;
                      border-right: 0;
                      max-width: unset;
                      width: unset;
                    }
                    .Open_tag {
                      display: flex;
                      color: #5ec83a;

                      figure {
                        width: 16px;
                        height: 16px;
                        margin-right: 5px;
                      }
                    }
                    .Solved_tag {
                      display: flex;
                      color: #f4ae23;

                      figure {
                        width: 16px;
                        height: 16px;
                        margin-right: 5px;
                      }
                    }
                  }
                  span {
                    font-size: 12px;
                    width: 100%;
                    padding: 0;
                    border-right: 0;
                    padding-bottom: 10px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .wrap_chat_content {
    width: calc(100% - 300px);
    position: relative;
    @include break-point(miniTabs) {
      width: 100%;
    }

    .wrap_profile_details {
      height: calc(80vh - 52px);
      @include scroll(y);
      overflow: auto;
      .head_setting {
        border-bottom: 1px solid #d9d9d9;
        min-height: 53px;
        .wrap_owner {
          display: flex;
          align-items: center;
          padding: 7px 15px;
          figure {
            width: 37px;
            height: 37px;
            border-radius: 100%;
            overflow: hidden;
            margin-bottom: 0;
            margin-right: 15px;
            img {
              height: 100%;
              object-fit: fill;
            }
          }

          h6 {
            margin-bottom: 0;
            font-weight: 600;
          }
        }
        .wrap_divide_dots {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .wrap_btn {
            .dropdown {
              .dropdown-toggle {
                background-color: #fff;
                border: 0;
                padding: 0;
                &::after {
                  content: "";
                  border: 0;
                }
                svg {
                  color: #000;
                  font-size: 24px;
                }
              }
              .dropdown-menu {
                padding: 0;
                overflow: hidden;
              }
            }
          }
        }
      }
      .form_profile_set {
        max-width: 98%;
        width: 100%;
        margin: 0 auto;

        .top_view_detail {
          display: flex;
          align-items: center;
          justify-content: space-between;
          background-color: $form_heading;
          padding: 5px 10px;
          .fig_pro_detail {
            display: flex;
            align-items: center;
            figure {
              width: 30px;
              height: 30px;
              margin-bottom: 0;
              margin-right: 10px;
              img {
                height: 100%;
                object-fit: cover;
              }
            }
            h6 {
              margin-bottom: 0;
              font-weight: 600;
              color: $white-color;
            }
          }
          .btn_cross {
            .view {
              background-color: $white-color;
              border: unset;
              font-size: 12px;
              padding: 2px 5px;
              margin-right: 20px;
            }
            svg {
              color: $white-color;
              font-size: 12px;
              cursor: pointer;
            }
          }
        }

        .chat_right {
          display: flex;
          justify-content: end;
          padding: 15px 0px;
          width: 100%;

          ._chat {
            max-width: 50%;
            width: 100%;
            display: flex;
            justify-content: end;

            @include break-point(mobile) {
              max-width: 98%;
            }
            span {
              background-color: #f0f3f5;
              padding: 10px 10px;
            }
            .time_card {
              display: unset;
              align-items: unset;
              background-color: unset;
              padding: unset;
              font-size: 12px;
              color: #768aa1;
              display: block;
            }
            figure {
              max-width: 35px;
              width: 100%;
              height: 35px;
              border-radius: 100%;
              overflow: hidden;
              margin-bottom: 0;
              margin-left: 15px;
              img {
                height: 100%;
                object-fit: fill;
                cursor: pointer;
              }
            }
            .wrap_card_chat {
              width: 100%;
              text-align: right;
              .single_img {
                max-width: 100%;
                width: 100%;
                height: 130px;
                border-radius: unset;
                overflow: hidden;
                margin-bottom: 10px;
                margin-left: unset;
                border-radius: 8px;
                overflow: hidden;

                img {
                  width: 100%;
                  height: 100%;
                  object-fit: fill;
                  max-width: 330px;
                  border-radius: 8px;
                }
                a {
                  width: 100%;
                  height: 100%;
                }
                &:last-of-type {
                  margin-bottom: 0px;
                }
              }
              .double_img_set {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                border: 1px solid $btn_border-common;
                border-radius: 8px;
                overflow: hidden;

                .double_img {
                  max-width: 50%;
                  width: 100%;
                  height: 100px;
                  border-radius: unset;
                  overflow: hidden;
                  margin-bottom: 0;
                  margin-left: unset;
                  position: relative;

                  &.cstm_more_image {
                    &::before {
                      content: "";
                      position: absolute;
                      width: 100%;
                      height: 100%;
                      background: rgba(0, 0, 0, 0.4);
                      cursor: pointer;
                    }
                    .image_count_cstm {
                      position: absolute;
                      color: #fff;
                      font-size: 18px;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      background-color: unset;
                      padding: 0;
                      top: 50%;
                      left: 50%;
                      right: auto;
                      transform: translate(-50%, -50%);
                      cursor: pointer;
                    }
                  }
                }
              }
              .cstm_video_chat {
                width: 100%;
                height: 100%;
              }
              a {
                color: $form_heading;
                width: 100%;
                height: 100%;
                .cstm_pdf_style {
                  display: flex;
                  justify-content: end;
                  .wra_Pfix {
                    display: flex;
                    align-items: center;
                    background-color: #f0f3f5;
                    padding: 10px 10px;
                    max-width: 280px;
                    width: 100%;
                    @include break-point(tablet) {
                      max-width: unset;
                      width: unset;
                    }
                    .wrap_pdf {
                      max-width: 15%;
                      width: 100%;
                      figure {
                        border-radius: unset;
                        margin-left: 0;
                      }
                    }
                    .details_pdf_wrap {
                      max-width: calc(100% - 17%);
                      width: 100%;
                      text-align: center;
                      p {
                        font-size: 13px;
                        margin-bottom: 0;
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .chat_left {
          display: flex;
          justify-content: start;
          padding: 15px 0px;
          width: 100%;
          ._chat {
            max-width: 50%;
            width: 100%;
            display: flex;
            justify-content: start;
            @include break-point(mobile) {
              max-width: 98%;
            }
            span {
              background-color: #f0f3f5;
              padding: 10px 10px;
            }
            .time_card {
              background-color: unset;
              padding: unset;
              font-size: 12px;
              color: #768aa1;
              display: block;
            }
            figure {
              max-width: 35px;
              width: 100%;
              height: 35px;
              border-radius: 100%;
              overflow: hidden;
              margin-bottom: 0;
              margin-right: 15px;
              img {
                height: 100%;
                object-fit: fill;
                cursor: pointer;
              }
            }
            .wrap_card_chat {
              width: 100%;
              .single_img {
                max-width: 250px;
                width: 100%;
                height: 100px;
                border-radius: unset;
                overflow: hidden;
                margin-bottom: 0;
                margin-left: unset;
                border-radius: 8px;
                overflow: hidden;
                img {
                  width: 100%;
                  height: 100%;
                  object-fit: fill;
                }
                a {
                  width: 100%;
                  height: 100%;
                }
              }
              .double_img_set {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                border: 1px solid $btn_border-common;

                .double_img {
                  max-width: 50%;
                  width: 100%;
                  height: 100px;
                  border-radius: unset;
                  overflow: hidden;
                  margin-bottom: 0;
                  margin-left: unset;
                  position: relative;

                  &.cstm_more_image {
                    &::before {
                      content: "";
                      position: absolute;
                      width: 100%;
                      height: 100%;
                      background: rgba(0, 0, 0, 0.4);
                      cursor: pointer;
                    }
                    .image_count_cstm {
                      position: absolute;
                      color: #fff;
                      font-size: 18px;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      background-color: unset;
                      padding: 0;
                      top: 50%;
                      left: 50%;
                      right: auto;
                      transform: translate(-50%, -50%);
                    }
                  }
                }
              }
              a {
                color: $form_heading;
                width: 100%;
                height: 100%;
                .cstm_pdf_style {
                  display: flex;
                  align-items: center;
                  background-color: #f0f3f5;
                  padding: 10px 10px;
                  max-width: 280px;
                  width: 100%;

                  @include break-point(tablet) {
                    max-width: unset;
                    width: unset;
                  }
                  .wrap_pdf {
                    max-width: 15%;
                    width: 100%;
                    figure {
                      border-radius: unset;
                    }
                  }
                  .details_pdf_wrap {
                    max-width: calc(100% - 17%);
                    width: 100%;
                    text-align: center;
                    p {
                      font-size: 13px;
                      margin-bottom: 0;
                    }
                  }
                }
              }
            }
          }
          .cstm_video_chat {
            width: 100%;
            height: 100%;
          }
        }
        .style_today_cstm {
          text-align: center;
          position: relative;
          margin-top: 10px;
          color: $nav-text-color;
          font-weight: 600;
          font-size: 14px;
          &::before {
            content: "";
            position: absolute;
            height: 2px;
            max-width: 43%;
            width: 100%;
            top: 50%;
            background-color: lightgray;
            left: 0;
          }
          &::after {
            content: "";
            position: absolute;
            height: 2px;
            max-width: 43%;
            width: 100%;
            top: 50%;
            right: 0;
            background-color: lightgray;
          }
        }
      }
      .wrap_type {
        position: absolute;
        width: 100%;
        bottom: 0;
        padding: 10px 0px;
        border-top: 1px solid #d9d9d9;
        .wrap_flex_typeing {
          display: flex;
          .cstm_input {
            max-width: 85%;
            padding: 0px 0px 0px 20px;
            width: 100%;
            &:focus {
              border-color: $white-color;
            }
            @include break-point(mobile) {
              max-width: 70%;
            }
          }
          .wrap_upload {
            display: flex;
            align-items: center;
            max-width: calc(100% - 85%);
            width: 100%;
            justify-content: end;
            .size_img {
              width: 30px;
              cursor: pointer;
            }
            .hidden_input {
              width: 0;
              height: 0;
              visibility: hidden;
              opacity: 0;
            }
            .form_sbmt_btn_light {
              padding: 5px 20px;
              max-width: 120px;
              width: 100%;
              margin-left: 10px;
            }
            @include break-point(mobile) {
              max-width: calc(100% - 70%);
            }
          }
        }
      }
      &.wrap_dispute_details {
        .head_setting {
          .wrap_owner {
            justify-content: space-between;
            .wrap_dispute_head {
              display: flex;
              align-items: center;
              figure {
                border-radius: unset;
              }
              .ticket_id {
                span {
                  display: block;
                  font-size: 12px;
                }
              }
            }
            .wrap_btn {
              .head_btn {
                border: 1px solid $filter_input;
                color: $btn_border-common;
                font-size: 13px;
                font-weight: 600;
                min-height: 35px;
                background-color: transparent;
                padding: 0px 10px;
                transition: all 0.5s ease-in-out;
                &:hover {
                  background-color: $btn_border-common;
                  color: $white-color;
                }
              }
            }
          }
        }
      }
    }
    .fresh_page_chat {
      text-align: center;
      .img_set_chat {
        max-width: 440px;
        width: 100%;
        margin: 0 auto;
      }
    }
    .wrap_toogle_icon {
      display: none;
      @include break-point(miniTabs) {
        display: block;
        text-align: start;
        padding: 10px 10px;
      }
      .cross_bar {
        svg {
          font-size: 24px;
        }
      }
    }
  }
}

.innerside_toogle {
  .wrapper_property_details {
    .wrapper_property {
      .wrap_chat_message {
        .wrapside_chat {
          .wrapper_accont_setting {
            left: 0;
            background: $white-color;
            z-index: 2;
          }
        }
      }
    }
  }
}
