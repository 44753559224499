@import "../../../scss/index.scss";

.wrap_subscription {
  .row {
    .wrap_card_subsription {
      a {
        border: 1px solid #c6dbf3;
        padding: 10px 10px 60px 10px;
        position: relative;
        color: $form_heading;
        min-height: 390px;
        .plan_tittle {
          position: absolute;
          right: 0;
          top: 0;
          background-color: #bfc8d2;
          color: $white-color;
          padding: 3px;
          font-size: 12px;
        }
        h4 {
          position: relative;
          padding-left: 14px;
          margin-top: 15px;
          .cstm_dolar {
            color: $btn_border-common;
            font-size: 18px;
            position: absolute;
            top: 3px;
            left: 0;
            font-weight: 400;
          }
          .cstm_zero {
            font-size: 16px;
          }
        }
        h5 {
          margin-bottom: 20px;
        }
        h3 {
        }
        ul {
          li {
            display: flex;
            align-items: start;
            padding-bottom: 5px;
            img {
              width: 20px;
              margin-right: 10px;
              height: 15px;
            }
            p {
              font-size: 14px;
              margin-bottom: 0;
            }
          }
        }
        &:hover {
          border: 1px solid $btn_border-common;
          .plan_tittle {
            background-color: $btn_border-common;
          }
        }
        &.active {
          border: 1px solid $btn_border-common;
          .plan_tittle {
            background-color: $btn_border-common;
          }
        }
      }
    }
  }
}
