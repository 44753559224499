@import "../../scss/index.scss";

.wrapper_accont_setting {
  max-width: 300px;
  width: 100%;
  height: calc(100vh - 35px);
  transition: all 0.4s linear;
  // overflow-y: auto;
  // position: fixed;
  @include break-point(miniTabs) {
    left: -100%;
    position: fixed;
  }
  &::-webkit-scrollbar {
    width: 0em;
    height: 0em;
  }
  .head_setting {
    padding: 15px;
    border-bottom: 1px solid $nav_border-color;
    border-right: 1px solid $nav_border-color;
    min-height: 53px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    h6 {
      margin-bottom: 0;
      font-size: 18px;
      color: $form_heading;
    }
    span {
      font-size: 14px;
      border: 1px solid lightgray;
      border-radius: 50%;
      display: inline-flex;
      padding: 3px;
      display: none;
      @include break-point(miniTabs){
        display: inline-flex;

      }
    }
  }
  .wrap_nav {
    ul {
      position: relative;
      li {
        a {
          width: 100%;
          padding-left: 5px;
          color: $form_heading;
          position: relative;
          svg {
            font-size: 22px;
            width: 54px;
            transition: all 0.2s ease-in-out;
          }
          span {
            font-size: 14px;
            padding: 15px 15px 15px 0px;
            width: calc(100% - 54px);
            border-right: 1px solid $nav_border-color;
            border-bottom: 1px solid $nav_border-color;
            transition: all 0.2s ease-in-out;
          }
          &:hover {
            color: $btn_border-common;
          }
          &.active {
            color: $btn_border-common;
            span {
              border-right: unset;
            }
            &::before {
              opacity: 1;
            }
          }
          &::before {
            content: "";
            position: absolute;
            width: 3px;
            height: 100%;
            left: 0;
            background-color: $btn_border-common;
            opacity: 0;
            transition: all 0.2s ease-in-out;
          }
        }
      }
    }
  }
}
