@import "../../scss/index.scss";

.wrapper_my_dispute {
  .cstm_row_whislist {
    background-color: $white-color;
    padding: 5px 0px 10px 5px;
    .wrap_search_bar {
      padding: 2px 4px;
      @include break-point(miniTabs){
        margin: 10px 0px;

      }
      .cstm_input {
        padding: 2px 30px 2px 30px;
      }
      .cstm_nav_icon {
        font-size: 12px;
        position: absolute;
        left: 6px;
        color: $form_heading;
      }
      .nav_btn_cstms {
        min-height: 33px;
      }
    }
    .wrap_select {
      .cstm_input_profile {
        border-radius: unset;
        border: 1px solid #b1c1d4;
        padding: 2px 20px 2px 20px;
        min-height: 39px;
        color: $form_heading;
        &:focus {
          border-color: #b1c1d4;
        }
      }
    }
  }
  .cstm_table_wrap {
    background-color: $white-color;
    .wrap_table {
      .table {
        color: $form_heading;
        thead {
          tr {
            background-color: #f3f9ff;
          }
        }
        tr {
          th {
            color: $form_heading;
            font-weight: 600;
            background-color: transparent;
            font-size: 16px;
            white-space: nowrap;
          }
          td {
            color: $form_heading;
            background-color: transparent;
            font-size: 13px;
          }
          .pending {
            color: #f4ae23;
          }
          .paid {
            color: #5ec83a;
          }
        }
      }
    }
  }
}


